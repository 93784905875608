import React from 'react';
import Func from "../../../utils/Func";
import '../../../css/style.css';
import TopMenuCall from "../../../js/TopMenuCall";

const RightMenu = ( { title, data, content = "" } ) => {
	if (data === undefined || data.length === 0) {
		return (<div></div>);
	}
	//console.log(data);
	let isgroup = data.isgroup;
	let ishome = data.ishome;
	let favourite_data = data.favourite_data;
	window.$current_active_report_type = data.current_active_report_type;
	window.$current_active_report_id = data.current_active_report_id;
	window.$current_active_report = data.current_active_report;
	window.$currency_list = data.currency_list;

    let favouritecontent = RightMenuFunc.FavouriteBox(favourite_data);
    let exchangeRateBox = RightMenuFunc.ExchangeRateUpdateBox(data.last_update_rates);
    let lastUpdateBox = RightMenuFunc.LastUpdateDateBox(data.last_update_report);
	let loadingImg = Func.ImageUrl() + 'loading.gif';

    //---- Content ----//
	let cont_slider = "";
	if(isgroup === 1 || ishome === 1){
        cont_slider = <div id="slidebar" style={{float: "right", width: "280px", display: "block", background: "none", paddingBottom: "15px"}}>
            <div style={{padding: "15px", paddingTop: "0px"}}>
                {exchangeRateBox}
                {lastUpdateBox}
            </div>
        </div>
    }
    
    let filter_search = <div style={{position: "absolute", width: "200px", top: "15px", right: "30px", backgroundColor: "#fff", borderRadius: "20px", zIndex: "1"}}>
        <div id="inner-search-report" style={{float: "left", width: "100%", opacity: "0.5"}}>
            <input id="search_report" name="search_report" placeholder="Click for all reports" style={{position: "relative", padding: "0 50px 0 15px", height: "32px", width: "100%", border: "none", borderRadius: "17px", margin: "4px 1px", WebkitTransition: "background .3s", transition: "background .3s", WebkitBoxSizing: "border-box", boxSizing: "border-box", outline: "none", fontSize: "13px"}} />
            <img src={loadingImg} style={{position: "absolute", top: "9px", right: "12px", height: "21px", zIndex: "2", display: "none"}} />
        </div>
    </div>

	// ADD FAVOURITE
	let cont_star = "";
	if(ishome != 1){
		let star_attr = {};
		star_attr["style"] = {position: "absolute", top: "20px", right: "10px", opacity: "0.6", padding: "5px", cursor: "pointer"};
		if(window.$current_active_report_type === "rep_sub_id"){
			star_attr["rep-sub-id"] = window.$current_active_report_id;
			star_attr["id"] = "star-after-title-rep-sub-id-" + window.$current_active_report_id;
		}
		else if(window.$current_active_report_type === "rep_id"){
			star_attr["rep-id"] = window.$current_active_report_id;
			star_attr["id"] = "star-after-title-rep-id-" + window.$current_active_report_id;
		}
		else if(window.$current_active_report_type === "rep_group_id"){
			star_attr["rep-group-id"] = window.$current_active_report_id;
			star_attr["id"] = "star-after-title-rep-group-id-" + window.$current_active_report_id;
		}
		star_attr["className"] = "star-search-report";
		cont_star = <a {...star_attr}>
			{RightMenuFunc.FavouriteStar()}
		</a>
	}

    let outercontent = <div id="box-outer-content-report" style={{position: "relative", float: "left", width: "100%", background: "#f1f1f1"}}>
        <div style={{float: "left", width: "100%", background: "#f1f1f1", position: "relative"}}>
            <div style={{position: "relative", float: "left"}}>
                <span className="link-name-favourite" style={{fontSize: "20px", lineHeight: "70px", marginLeft: "15px", marginRight: "46px", color: "#535c69"}}>
                    {title}
                </span>
                {cont_star}
            </div>
            {filter_search}
        </div>
        <div style={{position: "relative", float: "left", width: "100%", minHeight: "500px"}}>
            {cont_slider}
            <div id="workarea-content" style={{display: "block", overflowX: "hidden", minHeight: "941px", backgroundColor: "#fff"}}>
                {content}
                <div id="list-search-report" style={{position: "absolute",  top: "0px", left: "0px", width: "100%", minHeight: "100%", background: "#fff", display: "none", zIndex: "1000"}}>
                    <a id="close-list-search-report" href="" style={{position: "absolute", top: "0px", right: "0px", padding: "20px", zIndex: "1"}}>
                        <div className="close-icon"></div>
                    </a>
                    <div id="loading-search-report" style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", background: "#fff", display: "none", zIndex: "3"}}>
                        <img src={loadingImg} style={{display: "block", margin: "0 auto", width: "40px", marginTop: "30px", marginBottom: "30px"}} />
                    </div>
                    <div id="inner-list-search-report" style={{paddingTop: "20px", paddingBottom: "20px", paddingRight: "50px", paddingLeft: "15px"}}>
                    </div>
                </div>
            </div>
        </div>
        <div id="bg_black_menu" style={{position: "absolute", top: "0px", left: "0px", background: "#000", opacity: "0.6", width: "100%", height: "100%", zIndex: "1000", display: "none"}}>

        </div>
    </div>
    
    return (<div key="div-menu-right" id="div-menu-right" style={{minWidth: "1000px", position: "relative", float: "left", marginLeft: "250px", height: "100%", background: "#fff"}}>
        {favouritecontent}
		{outercontent}
	</div>);
}

const RightMenuFunc = {
	FavouriteStar(type = ""){
		let starBlueImg = Func.ImageUrl() + 'star_blue.png';
		let starBlackImg = Func.ImageUrl() + 'star_black.png';
		let star = "";
		if((window.$current_active_report && type === "") || type === "delete"){
			star = <img src={starBlueImg} alt="Delete favourite" title="Delete favourite" style={{width: "20px", display: "block"}} />
		}
		else{
			star = <img src={starBlackImg} alt="Add favourite" title="Add favourite" style={{width: "20px", display: "block"}} />
		}
		return star;
	},
	FavouriteBox(data = []){
		let n = data.length;
		let limit = n;
		let btn_more = <a id="more-favourite-report" href="" style={{fontSize: "14px", lineHeight: "60px", padding: "0 10px", userSelect: "none", textDecoration: "none", cursor: "pointer", float: "right", marginRight: "30px", position: "relative", display: "none"}}>
			<span className="text-more-favourite-report" style={{fontWeight: "bold", display: "inline-block", height: "59px", marginRight: "13px"}}>
				More
			</span>
			<span className="more-favourite-arrow-down" style={{position: "absolute", top: "50%", right: "10px", marginTop: "-5px"}}>
			</span>
		</a>
		//console.log(data);
		let content = [];
		for(let i=0; i<limit; i++){
			content.push(this.SingleFavouriteBox(data[i]));
		}
	
		let html = [];
		html.push(<div key="popup-favourite-report-1" style={{position: "relative", float: "left", width: "100%", background: "#fff", height: "60px", overflow: "hidden"}}>
			<div id="slide-favourite-report" style={{position: "absolute", height: "100%", top: "0px", left: "0px", whiteSpace: "nowrap", width: "100%"}}>
				{content}
				{btn_more}
				<div id="over-favourite-report" style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", display: "none"}} />
			</div>
		</div>);
		html.push(<div key="popup-favourite-report-2" id="popup-favourite-report" style={{position: "absolute", top: "67px", right: "30px", background: "#f6f9f9", width: "190px", zIndex: "1001", minHeight: "auto", padding: "0 15px", border: "1px solid #ddd", borderRadius: "3px", boxShadow: "0 7px 21px #ddd, 0 -1px 6px 0 #ddd", backgroundColor: "#fff", display: "none"}} />);
	
		return (html);
	},
	SingleFavouriteBox(data, isHide = false){
		let name = data["name"];
		let url = "";
		let addClass = "";
		let key = "link-favourite-rep-";
		if ("rep_sub_id" in data && !isNaN(data["rep_sub_id"])){
			url = data["url"];
			addClass = "link-favourite-rep-sub-id-" + data["rep_sub_id"];
			key += "sub-id-" + data["rep_sub_id"];
		}
		else if ("rep_id" in data && !isNaN(data["rep_id"])){
			url = data["url"];
			addClass = "link-favourite-rep-id-" + data["rep_id"];
			key += "id-" + data["rep_id"];
		}
		else{
			url = "index.php?page=" + data["tag"];
			addClass = "link-favourite-rep-group-id-" + data["rep_group_id"];
			key += "group-id-" + data["rep_group_id"];
		}
	
		let classname = "text-favourite-report";
		if(window.$current_active_report_type === "rep_sub_id" && ("rep_sub_id" in data) && window.$current_active_report_id === data["rep_sub_id"]){
			classname = "text-favourite-report-active";
		}
		else if(window.$current_active_report_type === "rep_id" && ("rep_id" in data) && !("rep_sub_id" in data) && window.$current_active_report_id === data["rep_id"]){
			classname = "text-favourite-report-active";
		}
		else if(window.$current_active_report_type === "rep_group_id" && ("rep_group_id" in data) && !("rep_id" in data) && window.$current_active_report_id === data["rep_group_id"]){
			classname = "text-favourite-report-active";
		}
	
		let css = {};
		if(!isHide){
			css = {display: "inline-block"};
		}
		
		return (<a key={key} className={"link-favourite-rep " + addClass} href={url} style={css}>
			<span className={classname}>
				{name}
			</span>
		</a>);
	},
	ExchangeRateUpdateBox(last_update_date){
		let data = ["THB", "JPY", "CNY", "EUR", "MYR"];
		let n = data.length;
		let content = [];
		for(let i=0;i<n;i++){
			let currency = data[i];
			let rate = window.$currency_list[currency];
			content.push(<div key={"slidebar-widget-item-rate-" + i} className="slidebar-widget-item">
				<div style={{float: "left"}}>
					<img src={Func.ImageUrl() + "flag/usd-rate.png"} style={{float: "left", width: "24px", marginRight: "6px"}} />
					<span style={{float: "left", fontSize: "11px", lineHeight: "24px"}}>
						USD
					</span>
				</div>
				<div style={{float: "left", margin: "0 8px", lineHeight: "24px"}}>
					&ndash;
				</div>
				<div style={{float: "left", marginRight: "22px"}}>
					<img src={Func.ImageUrl() + "flag/" + currency.toLowerCase() + "-rate.png"} style={{float: "left", width: "24px", marginRight: "6px"}} />
					<span style={{float: "left", fontSize: "11px", lineHeight: "24px"}}>
						{currency}
					</span>
				</div>
				<div style={{float: "left", lineHeight: "24px", color: "#535c69"}}>
					{rate.toFixed(4)}
				</div>
			</div>);
		}
		content.push(<div key={"slidebar-widget-item-rate-" + n} className="slidebar-widget-item">
			<span style={{fontStyle: "italic", color: "#7c7474", fontWeight: "normal"}}>
				{"Updated: " + last_update_date} 
			</span>
		</div>);
	
		let html = <div className="slidebar-widget">
			<div className="slidebar-widget-top" style={{backgroundColor: "#2fc7f7"}}>
				<div className="slidebar-widget-top-title">
					Exchange Rates Used
				</div>
				<a id="link-tp-exchange-rate" className="slidebar-widget-top-add" href="">
					<div className="circle plus" />
				</a>
			</div>
			<div className="slidebar-widget-content">
				{content}
			</div>
		</div>
	
		return (html);
	},
	LastUpdateDateBox(data = []){
		let content = [];
		let n = data.length;
		for(let i=0; i<n; i++){
			let country = data[i]["country"];
			let date = data[i]["update_time"];
			content.push(<div key={"slidebar-widget-item-date-" + i} className="slidebar-widget-item">
				<img src={Func.ImageUrl() + "flag/" + country.toLowerCase() + "-flag.png"} style={{float: "left", marginRight: "8px", width: "32px", marginTop: "2px"}} />
				<div style={{display: "block"}}>
					<span style={{display: "block", color: "#535c69", marginBottom: "3px"}}>
						{Func.capitalize(country)}
					</span>
					<span style={{display: "block", color: "#80868e", fontWeight: "normal"}}>
						{date}
					</span>
				</div>
			</div>);
		}
		content.push(<div key={"slidebar-widget-item-date-" + n} className="slidebar-widget-item">
			<span style={{fontStyle: "italic", color: "#7c7474", fontWeight: "normal"}}>
				All times are in GMT+7
			</span>
		</div>);
	
		let html = <div className="slidebar-widget">
			<div className="slidebar-widget-top" style={{backgroundColor: "#5cd1df"}}>
				<div className="slidebar-widget-top-title">
					Figures Last Updated
				</div>
			</div>
			<div className="slidebar-widget-content">
				{content}
			</div>
		</div>
		
		return (html);
	}
}

export { RightMenu, RightMenuFunc };