import React from "react";
import $ from "jquery";
import logoImg from "./images/logo-exo-tp.png";
import "./style.css";
import API from "../../../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../../../utils/encryption";
import LoadingCall from "../../../js/LoadingCall";

class TopBar extends React.Component {
  showMenu = () => {
    let menu = $("#menu-popup-user-menu");
    if (menu.is(":hidden")) {
      menu.show();
    } else {
      menu.hide();
    }
    //menu-popup-user-menu
  };
  showFAQdocument = () => {
    window.open(
      `${process.env.REACT_APP_EXO24_FAQ_URL}`
    );
  };
  logout = (loginData, storeLoginData) => {
    LoadingCall.show();
    const mainFunc = async () =>
      await API.post("auth/logout", {
        params: encryptJsonObject({
          conv_user_log_id: loginData.conv_user_log_id,
          conv_user_goo_id: loginData.conv_user_goo_id,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          storeLoginData({});
          window.location.href =
            `${process.env.REACT_APP_EXO24_SITE}/exopedia/tourplan`
          LoadingCall.hide();
          return false;
        } else {
          console.log(`Failes : ` + decrypt?.err_msg ?? "");
          LoadingCall.hide();
        }
      })
      .catch((err) => {
        console.log("error, " + err);
        LoadingCall.hide();
      });
  };
  render() {
    //const data = this.props.data;
    const { loginData, storeLoginData } = this.props;
    const userName = "No Name"; //(data.user_name || '');
    let html = [];

    html.push(
      <div key="topbar-1" id="header">
        <div id="header-inner">
          <div className="header-logo-block">
            <a id="logo_24_a" href="/" title="Home" className="logo">
              <img
                id="logo_24_img"
                src={logoImg}
                alt="Logo"
                style={{ width: "100px", marginTop: "0px" }}
              />
              {/* <img src={process.env.PUBLIC_URL + 'images/TopBar/logo-exo24.png'} /> */}
            </a>
          </div>
          <div style={{ position: "absolute", right: "0px" }}>
            <div className="user-block" style={{ right: "0px" }}>
              <div className="user-block-before"></div>
              <span className="user-name" style={{ lineHeight: "65px" }}>
                {userName}
              </span>
            </div>
            <div
              className="user-logout"
              style={{
                float: "left",
                paddingRight: "15px",
                paddingLeft: "10px",
                display: "none",
              }}
            >
              <button
                onClick={() => this.logout(loginData, storeLoginData)}
                style={{
                  cursor: "pointer",
                  lineHeight: "63px",
                  fontSize: "13px",
                  background: "none",
                  border: "none",
                  color: "#205bbe",
                  textDecoration: "underline",
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    html.push(
      <div
        key="topbar-2"
        id="menu-popup-user-menu"
        className="popup-window popup-window-no-paddings"
        style={{
          zIndex: 1000,
          position: "absolute",
          display: "none",
          top: "82px",
          right: "21px",
        }}
      >
        <div
          id="popup-window-content-menu-popup-user-menu"
          className="popup-window-content"
        >
          <div className="menu-popup" style={{ display: "block" }}>
            <div className="menu-popup-items">
              <button
                className="menu-popup-item user-menu-logOut"
                onClick={() => this.logout(loginData, storeLoginData)}
                style={{
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                }}
              >
                <span className="menu-popup-item-icon"></span>
                <span className="menu-popup-item-text">Log out</span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="popup-window-angly popup-window-angly-top"
          style={{ left: "23px", marginLeft: "0px" }}
        ></div>
      </div>
    );

    return html;
  }
}

export default TopBar;
