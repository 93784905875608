import React from 'react';
import $ from 'jquery';
import LoadingCall from "../js/LoadingCall";
  
const PopupCall = {
	event: function(){
		let boxContain = $("#box-container");
		boxContain.off("click" , ".small-popup-close, .popup-overlay");
		boxContain.on("click" , ".small-popup-close, .small-popup-overlay", function(event){
			PopupCall.smallHide();
		});
		$(".popup-close, .popup-overlay").on("click", function(event){
			PopupCall.hide();
		});
	},
	smallShow : function(){
		$(".small-popup-container").show();
		$('body').css("overflow","hidden");
	},
	smallHide : function(){
		$(".small-popup").remove();
		$('body').css("overflow","auto");
	},
	show : function(popup_height = ""){
		if(popup_height === ""){
			var height = $(window).height();
			popup_height = height - 200;
		}
		$(".popup-content").height(popup_height+"px");
		$(".popup-container").show();
		$('body').css("overflow","hidden");
	},
	hide : function(){
		setTimeout(function(){
			$(".popup-inner-content").html("");
			$(".popup-container").hide();
			$('body').css("overflow","auto");
			document.location.hash = "";
		},500);
	}
}

export default PopupCall;

$(document).ready(function(){
    //PopupCall.event();
});