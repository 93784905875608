import React from "react";
import $ from "jquery";
import API from "../../utils/API";
import Func from "../../utils/Func";
import {
  decryptWithApiResponse,
  encryptJsonObject,
} from "../../utils/encryption";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import LoadingCall from "../../js/LoadingCall";
import PopupCall from "../../js/PopupCall";
import { useLogin } from "../../context/LoginContext";

window.$conv_tp_log_id = "";
let typing;

const CopyRatesFunc = {
  event: function (loginData) {
    let boxContain = $("#box-container");

    boxContain.off("click", "#btn-more-options");
    boxContain.on("click", "#btn-more-options", function () {
      let box = $(".box-more-options");
      box.slideToggle();
      //return false;
    });
    boxContain.off("keyup", ".inp-multi-select");
    boxContain.on("keyup", ".inp-multi-select", function () {
      CopyRatesFunc.searchOptionFilterByJS(this);
    });
    boxContain.off("change", "input[name='date_from']");
    boxContain.on("change", "input[name='date_from']", function () {
      const date = $(this).val();
      const copy_to_date = CopyRatesFunc.getDefCopyToDate(date);
      $("input[name='copy_to_date'").val(copy_to_date);
    });
    boxContain.off("click", "#btn-cancel-copy-rates");
    boxContain.on("click", "#btn-cancel-copy-rates", function (e) {
      PopupCall.smallHide();
    });
    boxContain.off("click", "#btn-confirm-copy-rates");
    boxContain.on("click", "#btn-confirm-copy-rates", function (e) {
      e.preventDefault();
      //console.log(window.$conv_tp_log_id);
      //var data = $("#form-copy-rates").serializeArray();
      CopyRatesFunc.confirmForm(loginData);
    });
    boxContain.off("submit", "#form-copy-rates");
    boxContain.on("submit", "#form-copy-rates", function (e) {
      e.preventDefault();
      var elem = $(this);
      var type = elem.attr("type-name");
      if (CopyRatesFunc.checkAllRequiredFilter(type)) {
        return false;
      }
      var data = elem.serializeArray();
      CopyRatesFunc.submitForm(loginData, data);
    });
    //boxContain.off("click" , ".checkmark");
    //boxContain.on("click" , ".checkmark", function(e){
    boxContain.off("click", ".checkbox-checkmark");
    boxContain.on("click", ".checkbox-checkmark", function () {
      CopyRatesFunc.checkDisableFilter(this);
    });
    boxContain.off("click", ".select-filter");
    boxContain.on("click", ".select-filter", function (e) {
      CopyRatesFunc.clickSelectFilter(loginData, this, e);
    });
    boxContain.off("click", ".li-option-filter");
    boxContain.on("click", ".li-option-filter", function () {
      CopyRatesFunc.clickLiOptionFilter(loginData, this);
    });
    boxContain.off("click", ".checkbox-option-filter");
    boxContain.on("click", ".checkbox-option-filter", function () {
      CopyRatesFunc.clickCheckboxOptionFilter(loginData, this);
    });
  },
  outputError: function (err_msg = "") {
    if (err_msg === "") {
      err_msg =
        "Sorry, an error happended. Please try again later or contact TPHO.";
    }
    const html = (
      <div
        style={{
          position: "relative",
          float: "left",
          width: "375px",
          marginLeft: "26px",
          marginTop: "22px",
        }}
      >
        <img
          src={Func.ImageUrl() + "face_emoji.png"}
          style={{ position: "absolute", width: "50px" }}
        />
        <span style={{ float: "left", marginLeft: "70px", marginTop: "8px" }}>
          {err_msg}
        </span>
      </div>
    );
    let popup = Func.HtmlSmallPopupAlert(html, "Copy Rates - Output", {
      is_show: true,
      no_close: false,
      width: 450,
      height: 160,
    });
    popup = ReactDOMServer.renderToString(popup);
    $("#box-container").append(popup);
  },
  outputSuccess: function () {
    const html = (
      <div
        style={{
          position: "relative",
          float: "left",
          width: "196px",
          marginLeft: "112px",
          marginTop: "22px",
        }}
      >
        <span className="checkmark_success">
          <div className="checkmark_success_stem"></div>
          <div className="checkmark_success_kick"></div>
        </span>
        <span style={{ float: "left", marginLeft: "62px", marginTop: "18px" }}>
          Done successfully!
        </span>
      </div>
    );
    let popup = Func.HtmlSmallPopupAlert(html, "Copy Rates - Output", {
      is_show: true,
      no_close: false,
      width: 450,
      height: 160,
    });
    popup = ReactDOMServer.renderToString(popup);
    $("#box-container").append(popup);
  },
  outputAfterSubmit: function (count) {
    const html = (
      <div style={{ display: "block" }}>
        <span
          style={{ display: "block", textAlign: "center", marginTop: "10px" }}
        >
          {count} result to be applied. Please confirm to proceed.
        </span>
        <input
          className="btn-blue"
          id="btn-confirm-copy-rates"
          type="button"
          value="CONFIRM"
          style={{ position: "absolute", bottom: "15px", right: "15px" }}
        />
        <input
          className="btn-grey"
          id="btn-cancel-copy-rates"
          type="button"
          value="CANCEL"
          style={{ position: "absolute", bottom: "15px", right: "128px" }}
        />
      </div>
    );
    let popup = Func.HtmlSmallPopupAlert(html, "Copy Rates - Output", {
      is_show: true,
      no_close: true,
      width: 450,
      height: 160,
    });
    popup = ReactDOMServer.renderToString(popup);
    $("#box-container").append(popup);
  },
  confirmForm: function (login_data) {
    LoadingCall.show();
    const mainFunc = async () =>
      await API.post("copy_rates/confirm", {
        params: encryptJsonObject({
          conv_user_log_id: login_data.conv_user_log_id,
          conv_user_goo_id: login_data.conv_user_goo_id,
          conv_tp_log_id: window.$conv_tp_log_id,
        }),
      });
    mainFunc()
      .then((res) => {
        PopupCall.smallHide();
        const decrypt = decryptWithApiResponse(res?.data);
        console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          CopyRatesFunc.outputSuccess();
        } else {
          CopyRatesFunc.outputError(decrypt?.err_msg ?? "");
        }
        window.$conv_tp_log_id = "";
        LoadingCall.hide();
      })
      .catch((err) => {
        console.log("error, " + err);
        PopupCall.smallHide();
        CopyRatesFunc.outputError();
        window.$conv_tp_log_id = "";
        LoadingCall.hide();
      });
  },
  submitForm: function (loginData, data) {
    LoadingCall.show();
    /// console.log('params=', data);
    const mainFunc = async () =>
      await API.post("copy_rates/submit", {
        params: encryptJsonObject({
          form_data: data,
          conv_user_log_id: loginData.conv_user_log_id,
          conv_user_goo_id: loginData.conv_user_goo_id,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response1=", res);
        console.log("response2=", decrypt);
        if (decrypt?.status === "success" && decrypt?.conv_tp_log_id) {
          window.$conv_tp_log_id = decrypt.conv_tp_log_id;
          CopyRatesFunc.outputAfterSubmit(decrypt.count_result);
        } else {
          CopyRatesFunc.outputError(decrypt.err_msg);
          window.$conv_tp_log_id = "";
        }
        LoadingCall.hide();
      })
      .catch((err) => {
        console.log("error, " + err);
        window.$conv_tp_log_id = "";
        CopyRatesFunc.outputError();
        LoadingCall.hide();
      });
  },
  getDefCopyToDate: function (date) {
    const arr = date.split("-");
    const year = parseInt(arr[0]);
    const month = arr[1];
    const day = arr[2];
    return year + 1 + "-" + month + "-" + day;
  },
  searchOptionFilterByJS: function (elem) {
    var search = $(elem).val();
    var count = search.length;
    var type_name = $(elem).attr("type-name");
    var field = $(elem).attr("field-name");
    var select_filter = $(elem).parents(".select-filter").first();
    if ($(select_filter).hasClass("inp-disable")) {
      return false;
    }
    var idname = "options-filter-" + type_name + "-" + field;
    if (count >= 2 || count == 0) {
      if (typing != undefined) {
        clearTimeout(typing);
      }
      typing = setTimeout(function () {
        // console.log("*search=" + search);
        var lis = $("#" + idname).find(".li-option-filter");
        var n = lis.length;
        search = search.toLowerCase();
        let selectall_exist = CopyRatesFunc.checkSelectAllExist(
          type_name,
          field
        );
        for (var i = 0; i < n; i++) {
          var val = $(lis[i]).find(".checkbox-option-filter").attr("value");
          val = val !== undefined ? val.toLowerCase() : "";
          if (selectall_exist && val === "all") {
            let text_selectall = "Select All";
            if (search !== "") {
              text_selectall = "Select All Search Results";
            }
            $(lis[i]).find("label").text(text_selectall);
            $(lis[i]).show();
            continue;
          }
          var name = $(lis[i]).find("label").text().trim();
          name = name.toLowerCase();
          var pos = name.indexOf(search);
          var found = false;
          if (pos == 0 || count == 0) {
            found = true;
          }
          //if(!found && field == "select-agent_code"){
          if (!found) {
            var val = $(lis[i]).find(".checkbox-option-filter").attr("value");
            val = val !== undefined ? val.toLowerCase() : "";
            var pos = val.indexOf(search);
            //console.log(val+" "+search);
            if (pos == 0) {
              found = true;
            }
          }

          if (found) {
            $(lis[i]).show();
          } else {
            $(lis[i]).hide();
          }
        }
        $("#" + idname).show();
      }, 300);
    }
  },
  clickSelectFilter: function (loginData, elem, e) {
    var type = $(elem).attr("type-name");
    var field = $(elem).attr("field-name");
    var isMulti = $(elem).attr("multi-value");
    if ($(elem).hasClass("inp-disable")) {
      return;
    }
    if (
      $(e.target).hasClass("small-close-icon") ||
      $(e.target).hasClass("close-selected-filter")
    ) {
      var classname = $(e.target).attr("class");
      if (classname == "small-close-icon") {
        var elem = $(e.target).parent();
      } else {
        var elem = $(e.target);
      }
      var index = elem.attr("index");
      var relate = elem.attr("relate-field");
      $("#checkbox-option-filter-" + type + "-" + field + "-" + index).prop(
        "checked",
        false
      );
      elem.parent().remove();
      //$("#options-filter-"+type+"-"+field).hide();
      var elem_selectall = $(
        "#checkbox-option-filter-" + type + "-" + field + "-0"
      );
      if (elem_selectall.val() === "all" && elem_selectall.is(":checked")) {
        elem_selectall.prop("checked", false);
      }
      if (relate == 1) {
        let relate_fields = $("#box-inp-filter-" + type + "-" + field).attr(
          "relate-fields"
        );
        if (relate_fields !== "") {
          relate_fields = relate_fields.split("|xxx|");
        } else {
          relate_fields = [];
        }
        CopyRatesFunc.getInputRelateFilter(
          loginData,
          type,
          field,
          "",
          relate_fields
        );
      }
      return;
    }
    if (isMulti === "0" && $(e.target).hasClass("inp-multi-select")) {
      return;
    }
    var div = $("#options-filter-" + type + "-" + field);
    var inp_multi = $(elem).find(".inp-multi-select");
    if (div.is(":hidden")) {
      const count = div.find(".li-option-filter").length;
      if (count > 100) {
        LoadingCall.show();
        setTimeout(function () {
          div.show();
          if (isMulti === "0") {
            $(inp_multi).show();
          }
          $("#inp-select-filter-" + type + "-" + field).focus();
          LoadingCall.hide();
        }, 100);
      } else {
        div.show();
        if (isMulti === "0") {
          $(inp_multi).show();
        }
        $("#inp-select-filter-" + type + "-" + field).focus();
      }
    } else {
      div.hide();
      if (isMulti === "0") {
        $(inp_multi).hide();
      }
    }
  },
  clickLiOptionFilter: function (loginData, elem) {
    elem = $(elem);
    var multi = elem.attr("multi-value");
    if (multi === "0") {
      var type = elem.attr("type-name");
      if (type === "permission") {
        return;
      }
      LoadingCall.show();
      var field = elem.attr("field-name");
      var value = elem.attr("value");
      var name = elem.text();
      var relate = elem.attr("relate-field");
      var inp = $("#box-inp-filter-" + type + "-" + field);
      inp
        .find("input[name=" + field + "]")
        .val(value)
        .attr("value", value);
      $(".selected-filter-" + type + "-" + field)
        .text(name)
        .show();
      inp.find(".li-option-filter").css("background-color", "");
      elem.css("background-color", "#f1f1f1");
      $("#options-filter-" + type + "-" + field).hide();
      if (relate === "1") {
        let relate_fields = inp.attr("relate-fields");
        if (relate_fields !== "") {
          relate_fields = relate_fields.split("|xxx|");
        } else {
          relate_fields = [];
        }
        CopyRatesFunc.getInputRelateFilter(
          loginData,
          type,
          field,
          value,
          relate_fields
        );
      } else {
        LoadingCall.hide();
      }
    }
  },
  clickCheckboxOptionFilter: function (loginData, elem) {
    var elem = $(elem);
    var type = elem.attr("type-name");
    var field = elem.attr("field-name");
    var value = elem.attr("value");
    var index = elem.attr("index");
    var relate = elem.attr("relate-field");
    if (elem.is(":checked")) {
      if (value === "all") {
        var opts = $("#options-filter-" + type + "-" + field).find(
          ".checkbox-option-filter"
        );
        CopyRatesFunc.selectAll(opts, type, field);
      } else {
        let text_value = elem.parent().find("label").html();
        CopyRatesFunc.createSquareSelected(type, field, text_value, index);
      }
    } else {
      if (value === "all") {
        var opts = $("#options-filter-" + type + "-" + field).find(
          ".checkbox-option-filter"
        );
        CopyRatesFunc.unselectAll(opts);
        $(".selected-filter-" + type + "-" + field).remove();
      } else {
        $("#selected-filter-" + type + "-" + field + "-" + index).remove();
        var elem_selectall = $(
          "#checkbox-option-filter-" + type + "-" + field + "-0"
        );
        if (elem_selectall.val() === "all" && elem_selectall.is(":checked")) {
          elem_selectall.prop("checked", false);
        }
      }
    }
    if (relate == 1) {
      let relate_fields = $("#box-inp-filter-" + type + "-" + field).attr(
        "relate-fields"
      );
      if (relate_fields !== "") {
        relate_fields = relate_fields.split("|xxx|");
      } else {
        relate_fields = [];
      }
      CopyRatesFunc.getInputRelateFilter(
        loginData,
        type,
        field,
        value,
        relate_fields
      );
    }
    //CopyRatesFunc.checkRequiredFilter(type,elem);
  },
  checkSelectAllExist: function (type, field) {
    var is_multi = $("#box-inp-filter-" + type + "-" + field).attr(
      "multi-value"
    );
    var elem_selectall = $(
      "#checkbox-option-filter-" + type + "-" + field + "-0"
    );
    if (is_multi == 1 && elem_selectall.val() === "all") {
      return true;
    }
    return false;
  },
  unselectAll: function (data) {
    var n = data.length;
    for (var i = 0; i < n; i++) {
      data[i].checked = false;
      //$(data[i]).attr('checked', false);
    }
    //console.log(data);
  },
  selectAll: function (data, type, field) {
    //console.log(data.length);
    var n = data.length;
    for (var i = 0; i < n; i++) {
      var display = $(data[i]).parent().css("display");
      //console.log(display);
      if (display == "none") {
        continue;
      }
      if ($(data[i]).is(":checked")) {
        continue;
      }
      data[i].checked = true;
      var value = $(data[i]).parent().find("label").text();
      //console.log(value);
      var index = $(data[i]).attr("index");
      CopyRatesFunc.createSquareSelected(type, field, value, index);
    }
  },
  createSquareSelected: function (type, field, value, index) {
    var clone = $("#tmp-selected-filter").clone();
    clone.attr("id", "selected-filter-" + type + "-" + field + "-" + index);
    clone.attr("class", "selected-filter-" + type + "-" + field);
    clone.css({ display: "" });
    clone.find(".title").html(value);
    clone.find(".close-selected-filter").attr("type-name", type);
    clone.find(".close-selected-filter").attr("field-name", field);
    clone.find(".close-selected-filter").attr("index", index);
    //$(clone).insertBefore("#inp-select-filter-"+type+"-"+field);
    const box = $("#inp-select-filter-" + type + "-" + field).parent();
    $(box).append(clone);
  },
  checkDisableFilter: function (elem) {
    //var elem_checkbox = $(elem).parent().find(".checkbox-checkmark");
    var elem_checkbox = $(elem);
    var type_name = elem_checkbox.attr("type-name");
    var field_name = elem_checkbox.attr("field-name");
    var option_type = elem_checkbox.attr("option-type");

    if (option_type === undefined) {
      //console.log(field_name, 'undefined');
      return;
    }
    var checkbox = $("#checkbox-option-filter-" + type_name + "-" + field_name);
    //console.log(type_name, field_name, option_type, checkbox.is(':checked'));
    if (option_type === "select" || option_type === "multiselect") {
      var selectbox = $("#box-inp-filter-" + type_name + "-" + field_name)
        .find(".select-filter")
        .first();
    } else {
      var selectbox = $("#box-inp-filter-" + type_name + "-" + field_name)
        .find(".input-text-filter")
        .first();
    }
    if (checkbox.is(":checked") === false) {
      selectbox.addClass("inp-disable");
      if (option_type === "select" || option_type === "multiselect") {
        $("#options-filter-" + type_name + "-" + field_name).hide();
        $("#inp-select-filter-" + type_name + "-" + field_name).hide();
        if (option_type === "multiselect") {
          var opts = $("#options-filter-" + type_name + "-" + field_name).find(
            ".checkbox-option-filter"
          );
          CopyRatesFunc.unselectAll(opts);
          $(".selected-filter-" + type_name + "-" + field_name).remove();
        }
      } else if (option_type === "input") {
        selectbox.attr("disabled", true);
        selectbox.val("");
      }
    } else {
      selectbox.removeClass("inp-disable");
      if (option_type === "multiselect") {
        $("#inp-select-filter-" + type_name + "-" + field_name).show();
      }
      if (option_type === "input") {
        selectbox.removeAttr("disabled");
      }
    }
  },
  getInputRelateFilter: function (
    loginData,
    type_name,
    field_name,
    field_value = "",
    relate_fields = []
  ) {
    LoadingCall.show();
    const mainFunc = async () =>
      await API.post("copy_rates/relate_filter", {
        params: encryptJsonObject({
          type_name: type_name,
          field_name: field_name,
          field_value: field_value,
          relate_fields: relate_fields,
          form_data: $("#form-copy-rates").serializeArray(),
          conv_user_log_id: loginData.conv_user_log_id,
          conv_user_goo_id: loginData.conv_user_goo_id,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          LoadingCall.hide();
          const data = decrypt;
          const n = relate_fields.length;
          for (let i = 0; i < n; i++) {
            const relate_field = relate_fields[i];
            const box = $("#box-inp-filter-" + type_name + "-" + relate_field);
            const multi_value = box.attr("multi-value");
            const relate_fields_value = box.attr("relate_fields");
            const relate_field_value = relate_fields_value !== "" ? 1 : 0;
            $("#options-filter-" + type_name + "-" + relate_field).hide();
            if (multi_value === "0") {
              $(".selected-filter-" + type_name + "-" + relate_field)
                .text("")
                .hide();
              const opt_data = Func.HtmlOptionsFilter(
                type_name,
                relate_field,
                data[relate_field],
                "",
                "",
                { "relate-field": relate_field_value }
              );
              const ul_option = ReactDOMServer.renderToString(opt_data.options);
              const ul_elem = $(
                "#options-filter-" + type_name + "-" + relate_field
              ).find(".ul-option-filter");
              $(ul_elem).replaceWith(ul_option);
            } else {
              $(".selected-filter-" + type_name + "-" + relate_field).remove();
              const opt_data = Func.HtmlMultiOptionsFilter(
                type_name,
                relate_field,
                data[relate_field],
                "",
                "",
                { "relate-field": relate_field_value }
              );
              const ul_option = ReactDOMServer.renderToString(opt_data.options);
              const ul_elem = $(
                "#options-filter-" + type_name + "-" + relate_field
              ).find(".ul-option-filter");
              $(ul_elem).replaceWith(ul_option);
              //console.log(relate_field,opt_data);
              var inp_clone = $(
                "#inp-select-filter-" + type_name + "-" + relate_field
              ).clone();
              const box_selected = box.find(".select-filter div").first();
              const selected = ReactDOMServer.renderToString(opt_data.selected);
              $(box_selected).html(selected);
              $(box_selected).prepend(inp_clone);
            }
          }
          //   console.log(
          //     "Relate Filter is done, " + field_name + " : " + field_value
          //   );
        } else {
          console.log("Relate Filter is error, " + decrypt?.err_msg ?? "");
          LoadingCall.hide();
        }
      })
      .catch((err) => {
        console.log(`Failes : ${err}`);
        console.log("Relate Filter is error, " + err);
        LoadingCall.hide();
      });
  },
  checkAllRequiredFilter: function (type_name) {
    var check = false;
    var boxs = $(".box-inp-filter");
    var n = boxs.length;
    for (var i = 0; i < n; i++) {
      var box = $(boxs[i]);
      var required = box.attr("required-value");
      if (required !== "1") {
        continue;
      }
      var field = box.attr("field-name");
      var multi = box.attr("multi-value");
      var values = [];
      if (multi === "1") {
        $("input[name='" + field + "[]']:checked").each(function () {
          values.push(this.value);
        });
      } else {
        var val = $("input[name=" + field + "]").val();
        if (val != undefined && val != "") {
          values.push(val);
        }
      }
      var warn = $("#warning-required-filter-" + type_name + "-" + field);
      var inp = box.find(".select-filter");
      if (inp.length === 0) {
        inp = box.find(".inp-filter-" + type_name);
      }
      //console.log(inp);
      if (values.length === 0) {
        warn.show();
        $(inp).css("border-color", "#f07a7a");
        check = true;
      } else {
        warn.hide();
        $(inp).css("border-color", "#ddd");
      }
    }
    return check;
  },
  checkDestinationRelatedFilter: function (value = "", relate_fields = []) {},
  GetContent(data) {
    //console.log(data);
    let type_name = "copy-rates";
    const form = CopyRatesFunc.Form(data, type_name);

    let content = (
      <div
        style={{
          float: "left",
          width: "100%",
          fontSize: "13px",
          marginTop: "30px",
        }}
      >
        <div style={{ padding: "0 15px", display: "table", margin: "0 auto" }}>
          {form}
        </div>
      </div>
    );

    return content;
  },
  Form(data, type_name) {
    let title = (
      <span
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
          fontSize: "22px",
          fontWeight: "bold",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        Copy Rate Form
      </span>
    );

    let cur_year = new Date().getFullYear();
    let destination = data.def_val.destination;
    let date_from = cur_year + "-01-01";
    let date_to = cur_year + "-12-31";
    let service_type = "";
    let city = [];
    let supplier_code = [];
    let option_code = [];
    let rate_type = [""];
    let price_code = [];
    let db1_type = [];
    let db2_type = [];
    let db3_type = [];
    let db4_type = [];
    let db5_type = [];
    let db6_type = [];
    let class_code = [];

    /*destination = "TH";
        date_from = "2020-03-01";
        date_to = "2020-10-31";
        service_type = "AC";
        city = "PTY";
        supplier_code = ["AMPPTY"];
        option_code = [];*/
    const copy_to_date = CopyRatesFunc.getDefCopyToDate(date_from);
    //console.log(data);

    const destination_inp = Func.HtmlSingleSelectFilter(
      type_name,
      "destination",
      data.options.destination,
      destination,
      {
        required: 1,
        "relate-fields": [
          "service_type",
          "city",
          "supplier_code",
          "option_code",
          "rate_type",
          "price_code",
          "db1_type",
          "db2_type",
          "db3_type",
          "db4_type",
          "db5_type",
          "db6_type",
          "class_code",
        ],
      }
    );
    const service_type_inp = Func.HtmlSingleSelectFilter(
      type_name,
      "service_type",
      data.options.service_type,
      service_type,
      {
        width: "100%",
        required: 1,
        "relate-fields": [
          "city",
          "supplier_code",
          "option_code",
          "rate_type",
          "price_code",
          "db1_type",
          "db2_type",
          "db3_type",
          "db4_type",
          "db5_type",
          "db6_type",
          "class_code",
        ],
      }
    );
    const location_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "city",
      data.options.city,
      city,
      {
        width: "100%",
        required: 1,
        "relate-fields": [
          "supplier_code",
          "option_code",
          "rate_type",
          "price_code",
          "db1_type",
          "db2_type",
          "db3_type",
          "db4_type",
          "db5_type",
          "db6_type",
        ],
      }
    );
    const supplier_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "supplier_code",
      data.options.supplier_code,
      supplier_code,
      {
        width: "100%",
        "relate-fields": [
          "option_code",
          "rate_type",
          "price_code",
          "db1_type",
          "db2_type",
          "db3_type",
          "db4_type",
          "db5_type",
          "db6_type",
        ],
      }
    );
    const option_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "option_code",
      data.options.option_code,
      option_code,
      { width: "100%" }
    );
    const rate_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "rate_type",
      data.options.rate_type,
      rate_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const price_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "price_code",
      data.options.price_code,
      price_code,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db1_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db1_type",
      data.options.db1_type,
      db1_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db2_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db2_type",
      data.options.db2_type,
      db2_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db3_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db3_type",
      data.options.db3_type,
      db3_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db4_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db4_type",
      data.options.db4_type,
      db4_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db5_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db5_type",
      data.options.db5_type,
      db5_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const db6_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "db6_type",
      data.options.db6_type,
      db6_type,
      { width: "100%", "relate-fields": [], "inp-disable": true }
    );
    const class_inp = Func.HtmlSingleMultiSelectFilter(
      type_name,
      "class_code",
      data.options.class_code,
      class_code,
      { width: "100%", "relate-fields": [] }
    );

    let form = (
      <form
        id="form-copy-rates"
        type-name={type_name}
        method="post"
        style={{
          float: "left",
          padding: "25px",
          width: "500px",
          background: "#fff",
          margin: "0 auto",
          border: "1px solid #ddd",
          borderRadius: "20px",
        }}
      >
        {title}
        <span
          style={{
            float: "left",
            width: "100%",
            marginBottom: "15px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <span style={{ float: "left", marginRight: "10px" }}>
            Choose Destinations *{" "}
          </span>
          {Func.HtmlWarningRequired(type_name, "destination", destination, {
            width: "auto",
          })}
        </span>
        {destination_inp}
        {Func.Space("8px")}
        <span
          style={{
            float: "left",
            width: "100%",
            marginBottom: "15px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Copy From Date Range *
        </span>
        <div style={{ position: "relative", float: "left", width: "100%" }}>
          {Func.HtmlSingleDateFilter(type_name, "date_from", date_from, {
            required: 1,
            width: "47%",
          })}
          <div
            style={{
              float: "left",
              width: "6%",
              lineHeight: "36px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            &ndash;
          </div>
          {Func.HtmlSingleDateFilter(type_name, "date_to", date_to, {
            required: 1,
            width: "47%",
          })}
        </div>
        {Func.Space("8px")}
        <span
          style={{
            float: "left",
            width: "35%",
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <span style={{ float: "left" }}>Service Type *</span>
        </span>
        <div style={{ float: "left", width: "65%" }}>
          {Func.HtmlWarningRequired(type_name, "service_type", "")}
          {service_type_inp}
        </div>
        {Func.Space("8px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-city"}
              className="checkmark-text"
            >
              Location
            </label>
            <input
              name="checkbox_city"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="city"
              option-type="select"
              id={"checkbox-option-filter-" + type_name + "-city"}
              value="1"
              defaultChecked
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>
          {Func.HtmlWarningRequired(type_name, "city", "")}
          {location_inp}
        </div>
        {Func.Space("8px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-supplier_code"}
              className="checkmark-text"
            >
              Supplier Code(s)
            </label>
            <input
              name="checkbox_supplier_code"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="supplier_code"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-supplier_code"}
              value="1"
              defaultChecked
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>{supplier_inp}</div>
        {Func.Space("8px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-option_code"}
              className="checkmark-text"
            >
              Service Option Code(s)
            </label>
            <input
              name="checkbox_option_code"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="option_code"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-option_code"}
              value="1"
              defaultChecked
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>{option_inp}</div>
        {Func.Space("8px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-class_code"}
              className="checkmark-text"
            >
              Service Class(es)
            </label>
            <input
              name="checkbox_class_code"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="class_code"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-class_code"}
              value="1"
              defaultChecked
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>{class_inp}</div>
        {Func.Space("8px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-rate_type"}
              className="checkmark-text"
            >
              Rate Type(s)
            </label>
            <input
              name="checkbox_rate_type"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="rate_type"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-rate_type"}
              value="1"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>{rate_inp}</div>
        {Func.Space("1px")}
        <div
          style={{
            float: "right",
            width: "65%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-keep_rate"}
              className="checkmark-text"
              style={{ color: "#ec7d28" }}
            >
              Keep the promotion rates
            </label>
            <input
              name="keep_rate"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="keep_rate"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-keep_rate"}
              value="1"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        {Func.Space("1px")}
        <div
          style={{
            float: "left",
            width: "35%",
            margin: "10px 0",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "35px",
          }}
        >
          <label className="checkbox-container">
            <label
              htmlFor={"checkbox-option-filter-" + type_name + "-price_code"}
              className="checkmark-text"
            >
              Price Code(s)
            </label>
            <input
              name="checkbox_price_code"
              type="checkbox"
              className="checkbox-checkmark"
              type-name={type_name}
              field-name="price_code"
              option-type="multiselect"
              id={"checkbox-option-filter-" + type_name + "-price_code"}
              value="1"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div style={{ float: "left", width: "65%" }}>{price_inp}</div>
        {Func.Space("8px")}
        <div style={{ float: "left", width: "100%" }}>
          <div
            className="btn-more-expend"
            id="btn-more-options"
            style={{ float: "right", fontWeight: "bold" }}
          >
            more options
          </div>
        </div>
        <div
          className="box-more-options"
          style={{
            float: "left",
            width: "100%",
            marginTop: "20px",
            display: "none",
          }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db1_type"}
                className="checkmark-text"
              >
                DB1 Type(s)
              </label>
              <input
                name="checkbox_db1_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db1_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db1_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db1_inp}</div>
        </div>
        {Func.Space("8px")}
        <div
          className="box-more-options"
          style={{ float: "left", width: "100%", display: "none" }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db2_type"}
                className="checkmark-text"
              >
                DB2 Type(s)
              </label>
              <input
                name="checkbox_db2_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db2_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db2_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db2_inp}</div>
        </div>
        {Func.Space("8px")}
        <div
          className="box-more-options"
          style={{ float: "left", width: "100%", display: "none" }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db3_type"}
                className="checkmark-text"
              >
                DB3 Type(s)
              </label>
              <input
                name="checkbox_db3_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db3_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db3_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db3_inp}</div>
        </div>
        {Func.Space("8px")}
        <div
          className="box-more-options"
          style={{ float: "left", width: "100%", display: "none" }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db4_type"}
                className="checkmark-text"
              >
                DB4 Type(s)
              </label>
              <input
                name="checkbox_db4_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db4_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db4_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db4_inp}</div>
        </div>
        {Func.Space("8px")}
        <div
          className="box-more-options"
          style={{ float: "left", width: "100%", display: "none" }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db5_type"}
                className="checkmark-text"
              >
                DB5 Type(s)
              </label>
              <input
                name="checkbox_db5_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db5_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db5_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db5_inp}</div>
        </div>
        {Func.Space("8px")}
        <div
          className="box-more-options"
          style={{ float: "left", width: "100%", display: "none" }}
        >
          <div
            style={{
              float: "left",
              width: "35%",
              margin: "10px 0",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "35px",
            }}
          >
            <label className="checkbox-container">
              <label
                htmlFor={"checkbox-option-filter-" + type_name + "-db6_type"}
                className="checkmark-text"
              >
                DB6 Type(s)
              </label>
              <input
                name="checkbox_db6_type"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="db6_type"
                option-type="multiselect"
                id={"checkbox-option-filter-" + type_name + "-db6_type"}
                value="1"
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div style={{ float: "left", width: "65%" }}>{db6_inp}</div>
        </div>
        {Func.Space("15px")}
        <span
          style={{
            float: "left",
            width: "100%",
            marginBottom: "15px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Copy To Date Range
        </span>
        <div style={{ position: "relative", float: "left", width: "100%" }}>
          <div style={{ float: "left", width: "47%", marginRight: "6%" }}>
            {Func.HtmlSingleDateFilter(
              type_name,
              "copy_to_date",
              copy_to_date,
              { required: 1 }
            )}
            <i style={{ float: "left", fontSize: "11px", color: "red" }}>
              *default to date (+1 year on from date)
            </i>
          </div>
          <div
            style={{
              position: "relative",
              float: "left",
              width: "47%",
              paddingTop: "1px",
            }}
          >
            <label className="checkbox-container">
              Update to <i style={{ fontWeight: "bold" }}>Provisional</i> Rate
              Status
              <input
                name="provision"
                type="checkbox"
                className="checkbox-checkmark"
                type-name={type_name}
                field-name="provision"
                id={"checkbox-option-filter-" + type_name + "-provision"}
                value="1"
                defaultChecked
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <div
          style={{
            float: "left",
            width: "100%",
            marginTop: "25px",
            textAlign: "center",
          }}
        >
          <input
            className="btn-blue"
            type="submit"
            value="Copy Rate"
            style={{ width: "150px", fontWeight: "bold" }}
          />
        </div>
      </form>
    );

    return form;
  },
};

export default CopyRatesFunc;
