import React from "react";
import { Container, ContainerFunc } from "../Container/index";
import "./style.css";
import CopyRatesFunc from "./func";
import { MainProvider } from "../../context/MainContext";
import { useLogin } from "../../context/LoginContext";

const CopyRates = () => {
  const { loginData } = useLogin();
  const url = "copy_rates.php";
  CopyRatesFunc.event(loginData);
  return (
    <MainProvider url={url}>
      <Container url={url} />
    </MainProvider>
  );
};

export default CopyRates;
