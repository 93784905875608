import React from 'react';
import Func from "../../../utils/Func";
import '../../../css/style.css';

export default {
    GetContent(data){
        return "hello";
    },
    ContentSalesReport(type_name, content = ''){
        let html = [];
        let hidden = [];
        //console.log(type_name);
        html.push(<div key="content-sales-report-1" style={{position: "relative", float: "left", width: "100%", backgroundColor: "#fff", padding: "15px 0"}}>
            <div style={{padding: "0 15px"}}>
                <div id="inner-right-content" style={{float: "left", width: "100%"}}>
                    {content}
                </div>
            </div>
        </div>);
    
        return html;
    }
}