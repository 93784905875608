// eslint-disable-next-line import/no-anonymous-default-export
export default {
  serverName: process.env.REACT_APP_SERVERNAME,
  mainDir: process.env.REACT_APP_MAINDIR,
  googleApiKey: process.env.REACT_APP_GOOGLEAPIKEY,
  clientId: process.env.REACT_APP_CLIENTID,
  apiUrl: process.env.REACT_APP_APIURL,
  apiKey: process.env.REACT_APP_APIKEY,
  baseUrl: process.env.REACT_APP_BASEURL,
  site: {},
};
