import React from 'react';
import $ from 'jquery';
  
const ScreenCall = {
  event(){

	},
	setWidthExpand(){
		var win_w = $(window).width();
		var menu_right_w = win_w - 20;
		$("#div-menu-right").css("min-width",menu_right_w+"px");
	},
	setWidth(){
		// SET WIDTH
		var win_w = $(window).width();
		var menu_left_w = $("#div-menu-left").width();
		var menu_right_w = (win_w - menu_left_w);
		$("#div-menu-right").css("min-width",menu_right_w+"px");
	},
	setHeight(){
		// SET HEIGHT
		var win_h = $(window).height();
		var head_h = $("#header-inner").height() + 5;
		var contain_h = win_h - head_h;
		$("#box-container").css("min-height",contain_h+"px");

		// SET HEIGHT OUTER CONTENT
		var outer_h = contain_h - 60;
		$("#box-outer-content-report").css("min-height",outer_h+"px");
  }
}

export default ScreenCall;

/*
$(document).ready(function(){
    ScreenCall.setWidth();
    ScreenCall.setHeight();
});
$(window).resize(function(){
    ScreenCall.setWidth();
    ScreenCall.setHeight();
});
*/