import React from "react";
import $ from "jquery";
import API from "../utils/API";
import Func from "../utils/Func";
import { decryptWithApiResponse, encryptJsonObject } from "../utils/encryption";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import LoadingCall from "../js/LoadingCall";
import "../components/Container/RightMenu";
import { RightMenuFunc } from "../components/Container/RightMenu";

const TopMenuCall = {
  event: function () {
    let boxContain = $("#box-container");
    let slidestatus = {};

    boxContain.off("click", ".dropdown-sub-list-search-report");
    boxContain.on("click", ".dropdown-sub-list-search-report", function () {
      var rep_id = $(this).attr("rep-id");
      var inside = $("#inside-sub-list-search-report-" + rep_id);
      if (inside.is(":hidden")) {
        inside.show();
      } else {
        inside.hide();
      }
    });

    boxContain.off("mouseover", "#inner-search-report");
    boxContain.on("mouseover", "#inner-search-report", function () {
      $(this).css("opacity", "1.0");
    });
    boxContain.off("mouseout", "#inner-search-report");
    boxContain.on("mouseout", "#inner-search-report", function () {
      if (!$("#search_report").is(":focus")) {
        $(this).css("opacity", "0.5");
      }
    });

    $(document).mousedown(function (e) {
      // The latest element clicked
      var elem = $(e.target);
      if (
        $("#popup-favourite-report").is(":visible") &&
        !elem.hasClass("#popup-favourite-report") &&
        elem.parents("#popup-favourite-report").length == 0 &&
        elem.attr("id") != "more-favourite-report" &&
        elem.parents("#more-favourite-report").length == 0
      ) {
        $("#popup-favourite-report").hide();
      } else if (
        $("#list-search-report").is(":visible") &&
        elem.attr("id") != "list-search-report" &&
        elem.attr("id") != "inner-search-report" &&
        elem.parents("#list-search-report").length == 0 &&
        elem.parents("#inner-search-report").length == 0
      ) {
        $("#list-search-report").slideUp(300, function () {
          LoadingCall.hide();
        });
      }
    });

    boxContain.off("click", "#more-favourite-report");
    boxContain.on("click", "#more-favourite-report", function (e) {
      e.preventDefault();
      if ($("#popup-favourite-report").is(":hidden")) {
        $("#popup-favourite-report").show();
      } else {
        $("#popup-favourite-report").hide();
      }
    });

    boxContain.off("focus keyup input", "#search_report");
    boxContain.on("focus keyup input", "#search_report", function () {
      var search = $(this).val();
      var n = search.length;
      if (n >= 3 || n == 0) {
        if ($("#list-search-report").is(":hidden")) {
          $("#list-search-report").slideDown(300, function () {
            LoadingCall.show();
          });
        } else {
          LoadingCall.show();
        }
        setTimeout(function () {
          TopMenuCall.get_list_search_report(search);
        }, 500);
      }
    });

    boxContain.off("click", "#close-list-search-report");
    boxContain.on("click", "#close-list-search-report", function () {
      $("#list-search-report").slideUp(300, function () {
        LoadingCall.hide();
      });
    });

    boxContain.off("click", ".star-search-report");
    boxContain.on("click", ".star-search-report", function () {
      var elem = $(this);
      var rep_group_id = $(this).attr("rep-group-id");
      var rep_id = $(this).attr("rep-id");
      var rep_sub_id = $(this).attr("rep-sub-id");
      var current_active_report_type = $("#current_active_report_type").val();
      var current_active_report_id = $("#current_active_report_id").val();

      LoadingCall.show();
      const mainFunc = async () =>
        await API.post("favourite_report", {
          params: encryptJsonObject({
            idu: process.env.REACT_APP_USER_ID,
            rep_group_id: rep_group_id,
            rep_id: rep_id,
            rep_sub_id: rep_sub_id,
            current_active_report_type: current_active_report_type,
            current_active_report_id: current_active_report_id,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            let star = "";
            if (decrypt?.star_type) {
              star = RightMenuFunc.FavouriteStar(decrypt.star_type);
              star = ReactDOMServer.renderToString(star);
              elem.find("img").replaceWith(star);
            }
            var star_title = "";
            if (rep_group_id !== undefined && rep_group_id !== 0) {
              star_title = $("#star-after-title-rep-group-id-" + rep_group_id);
            } else if (rep_sub_id !== undefined && rep_sub_id !== 0) {
              star_title = $("#star-after-title-rep-sub-id-" + rep_sub_id);
            } else if (rep_id !== undefined && rep_id !== 0) {
              star_title = $("#star-after-title-rep-id-" + rep_id);
            }
            if (star_title.length > 0) {
              star_title.find("img").replaceWith(star);
            }
            if (decrypt?.favourite_data) {
              let add_favourite = RightMenuFunc.SingleFavouriteBox(
                decrypt.favourite_data
              );
              add_favourite = ReactDOMServer.renderToString(add_favourite);
              $("#slide-favourite-report").append(add_favourite);
            } else if (decrypt?.remove_rep_group_id) {
              $(
                ".link-favourite-rep-group-id-" + decrypt.remove_rep_group_id
              ).remove();
            } else if (decrypt?.remove_rep_id) {
              $(".link-favourite-rep-id-" + decrypt.remove_rep_id).remove();
            } else if (decrypt?.remove_rep_sub_id) {
              $(
                ".link-favourite-rep-sub-id-" + decrypt.remove_rep_sub_id
              ).remove();
            }
            var link = elem.parent().find(".link-name-favourite");
            $(link)
              .removeClass(decrypt.oldLinkClass)
              .addClass(decrypt.linkClass);
            TopMenuCall.set_init_favourite_bar(1);
          }
          LoadingCall.hide();
        })
        .catch((err) => {
          console.log("error, " + err);
          //CopyRatesFunc.outputError();
          LoadingCall.hide();
        });
    });

    boxContain.off("mousedown", "#slide-favourite-report");
    boxContain.on("mousedown", "#slide-favourite-report", function (e) {
      var outer_width = parseFloat($(this).parent().width());
      var width = parseFloat($(this).width());
      if (width <= outer_width) {
        return false;
      }
      if (slidestatus.move) {
        return false;
      }
      slidestatus.drag = true;
      slidestatus.move = false;
      slidestatus.animate = false;
      slidestatus.touchtype = null;
      slidestatus.movetype = null;
      slidestatus.startX = e.pageX;
      slidestatus.startY = e.pageY;
      console.log("down");
      this.addEventListener(
        "mousemove",
        function (e) {
          if (!slidestatus.drag) {
            return false;
          }
          e.preventDefault();
          $("#over-favourite-report").show();
          var elem = this;
          var offX = e.pageX - slidestatus.startX;
          var posL = elem.style.left;
          if (!isNaN(posL)) {
            posL = 0;
          } else {
            posL = parseFloat(posL.split("px")[0]);
          }
          var pL = posL + offX;
          var diff =
            parseFloat($(this).parent().width()) - parseFloat($(this).width());
          if (pL > 0) {
            pL = 0;
          } else if (pL < diff) {
            pL = diff;
          }
          //console.log(posL,pL,diff);
          elem.style.left = pL + "px";
          slidestatus.startX = e.pageX;
          slidestatus.startY = e.pageY;
          slidestatus.move = true;
          slidestatus.posL = pL;
          console.log("move");
        },
        false
      );
      this.addEventListener(
        "mouseup",
        function (e) {
          slidestatus.drag = false;
          slidestatus.move = false;
          $("#over-favourite-report").hide();
          console.log("up");
        },
        false
      );
      this.addEventListener(
        "mouseleave",
        function (e) {
          slidestatus.drag = false;
          slidestatus.move = false;
          $("#over-favourite-report").hide();
          console.log("leave");
        },
        false
      );
    });

    if ($("#list-search-report").is(":visible")) {
      TopMenuCall.set_css_column_list_search();
    }
    TopMenuCall.set_init_favourite_bar();
    $(window).resize(function () {
      let slide = document.getElementById("slide-favourite-report");

      slidestatus.drag = false;
      slidestatus.move = false;
      $("#over-favourite-report").hide();
      if (slide !== null) {
        slide.style.left = "0px";
      }
      if ($("#list-search-report").is(":visible")) {
        TopMenuCall.set_css_column_list_search();
      }
      TopMenuCall.set_init_favourite_bar(1);
    });
  },
  get_list_search_report(search) {
    LoadingCall.show();
    const mainFunc = async () =>
      await API.post("search_report", {
        params: encryptJsonObject({
          idu: process.env.REACT_APP_USER_ID,
          search: search,
        }),
      });
    mainFunc()
      .then((res) => {
        const decrypt = decryptWithApiResponse(res?.data);
        // console.log("response=", decrypt);
        if (decrypt?.status === "success") {
          let content = this.html_search_report(
            decrypt.report_data,
            decrypt.arr_favourite
          );
          content = ReactDOMServer.renderToString(content);
          $("#inner-list-search-report").html(content);
          this.set_css_column_list_search();
        }
        LoadingCall.hide();
      })
      .catch((err) => {
        console.log("search = " + search + ", error, " + err);
        LoadingCall.hide();
      });
  },
  html_search_report(data = [], arr_favourite = []) {
    let report_group_data = data["report_group"];
    let report_data = data["report"];
    let report_sub_data = data["report_sub"];
    let n = report_group_data.length;
    let cont = [];
    let ul = [];

    for (let i = 0; i < n; i++) {
      let rep_group_id = report_group_data[i]["rep_group_id"];
      let name = report_group_data[i]["name"];
      let tag = report_group_data[i]["tag"];
      let star = "";
      let linkClass = "";
      if (arr_favourite["rep_group"][rep_group_id] !== undefined) {
        star = RightMenuFunc.FavouriteStar("delete");
        linkClass = "head-link-is-favourite";
      } else {
        star = RightMenuFunc.FavouriteStar("add");
        linkClass = "head-link-is-not-favourite";
      }
      let li = [];
      li.push(
        <li
          className="head-list-search-report"
          style={{
            listStyle: "none",
            padding: "0px 18px",
            position: "relative",
            cursor: "pointer",
            marginTop: "8px",
            marginBottom: "15px",
            borderRadius: "18px",
          }}
        >
          <div
            style={{ display: "block", padding: "10px 0", marginRight: "15px" }}
          >
            <a
              className={"link-name-favourite " + linkClass}
              href={"?page=" + tag}
              style={{ textDecoration: "none", fontSize: "17px" }}
            >
              {name}
            </a>
          </div>
          <a
            className="star-search-report"
            rep-group-id={rep_group_id}
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              opacity: "0.6",
              padding: "5px",
            }}
          >
            {star}
          </a>
        </li>
      );
      if (report_data[rep_group_id] !== undefined) {
        li.push(
          this.html_sub_search_report(report_data[rep_group_id], {
            rep_group_id: rep_group_id,
            rep_group_name: name,
            rep_group_tag: tag,
            arr_favourite: arr_favourite["rep"],
            arr_favourite_sub: arr_favourite["rep_sub"],
            report_sub: report_sub_data,
          })
        );
      }
      cont.push(<ul className="group-list-search-report">{li}</ul>);
    }

    return cont;
  },
  html_sub_search_report(data = [], addon = []) {
    let li = [];
    let n = data.length;
    let arr_favourite = addon["arr_favourite"];
    let arr_favourite_sub = addon["arr_favourite_sub"];
    let report_sub = addon["report_sub"];
    for (let i = 0; i < n; i++) {
      let rep_id = data[i]["rep_id"];
      let name = data[i]["name"];
      let url = data[i]["url"];
      let rep_group_id = data[i]["rep_group_id"];
      let linkClass = "";
      let star = "";
      if (arr_favourite[rep_id] !== undefined) {
        star = RightMenuFunc.FavouriteStar("delete");
        linkClass = "link-is-favourite";
      } else {
        star = RightMenuFunc.FavouriteStar("add");
        linkClass = "link-is-not-favourite";
      }
      if (report_sub[rep_id] !== undefined) {
        li.push(
          <li
            className="sub-list-search-report"
            style={{
              listStyle: "none",
              margin: "8px 0",
              padding: "8px 18px",
              position: "relative",
              cursor: "pointer",
              borderRadius: "18px",
            }}
          >
            <div style={{ display: "block", marginRight: "15px" }}>
              <a
                className={"link-name-favourite " + linkClass}
                href={url}
                style={{ textDecoration: "none", fontSize: "14px" }}
              >
                {name}
              </a>
            </div>
            <div
              className="dropdown-sub-list-search-report"
              rep-id={rep_id}
              style={{
                padding: "15px",
                background: "none",
                position: "absolute",
                top: "3px",
                right: "37px",
              }}
            >
              <span
                className="arrow-down"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "11px",
                  marginTop: "-5px",
                  cursor: "pointer",
                }}
              />
            </div>
            <a
              className="star-search-report"
              rep-id={rep_id}
              style={{
                position: "absolute",
                top: "3px",
                right: "10px",
                opacity: "0.6",
                padding: "5px",
              }}
            >
              {star}
            </a>
          </li>
        );
        li.push(
          this.html_sub_menu_search_report(rep_id, report_sub[rep_id], {
            arr_favourite: arr_favourite_sub,
          })
        );
      } else {
        li.push(
          <li
            className="sub-list-search-report"
            style={{
              listStyle: "none",
              margin: "8px 0",
              padding: "8px 18px",
              position: "relative",
              cursor: "pointer",
              borderRadius: "18px",
            }}
          >
            <div style={{ display: "block", marginRight: "15px" }}>
              <a
                className={"link-name-favourite " + linkClass}
                href={url}
                style={{ textDecoration: "none", fontSize: "14px" }}
              >
                {name}
              </a>
            </div>
            <a
              className="star-search-report"
              rep-id={rep_id}
              style={{
                position: "absolute",
                top: "3px",
                right: "10px",
                opacity: "0.6",
                padding: "5px",
              }}
            >
              {star}
            </a>
          </li>
        );
      }
    }
    return li;
  },
  html_sub_menu_search_report(rep_id, data = [], addon = []) {
    let n = data.length;
    if (n == 0) {
      return "";
    }
    let arr_favourite = addon["arr_favourite"];
    let cont = [];
    for (let i = 0; i < n; i++) {
      let rep_sub_id = data[i]["rep_sub_id"];
      let name = data[i]["name"];
      let url = data[i]["url"];
      let star = "";
      let linkClass = "";
      if (arr_favourite[rep_sub_id] !== undefined) {
        star = RightMenuFunc.FavouriteStar("delete");
        linkClass = "link-is-favourite";
      } else {
        star = RightMenuFunc.FavouriteStar("add");
        linkClass = "link-is-not-favourite";
      }
      cont.push(
        <div
          style={{
            listStyle: "none",
            margin: "3px 0",
            marginLeft: "20px",
            padding: "6px 18px",
            position: "relative",
          }}
        >
          <div style={{ display: "block", marginRight: "15px" }}>
            <a
              className={"link-name-favourite " + linkClass}
              href={url}
              style={{ textDecoration: "none", fontSize: "13px" }}
            >
              {name}
            </a>
          </div>
          <a
            className="star-search-report"
            rep-id={rep_id}
            rep-sub-id={rep_sub_id}
            style={{
              position: "absolute",
              top: "1px",
              right: "10px",
              opacity: "0.6",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {star}
          </a>
        </div>
      );
    }
    let li = (
      <li
        className="inside-sub-list-search-report"
        id={"inside-sub-list-search-report-" + rep_id}
        style={{
          listStyle: "none",
          margin: "0px",
          padding: "0",
          position: "relative",
          display: "none",
        }}
      >
        {cont}
      </li>
    );

    return li;
  },
  set_css_column_list_search() {
    var width = $("#list-search-report").width();
    var col = Math.round(width / 300);
    var group = $(".group-list-search-report");
    if (col > 5) {
      col = 5;
    } else if (col < 3) {
      col = 3;
    }
    if (col > group.length) {
      col = group.length;
    }
  },
  set_init_favourite_bar(isResize) {
    if (isResize == 1) {
      $(".link-favourite-rep").show();
      $("#popup-favourite-report").hide();
    }
    var slide = $("#slide-favourite-report");
    var w_win = $("#box-container").width() - 15;
    var list = slide.find(".link-favourite-rep");
    //console.log("w="+w_win);
    var n = list.length;
    var moreList = [];
    var check = 0;
    for (var i = 0; i < n; i++) {
      var classname = $(list[i])
        .attr("class")
        .replace("link-favourite-rep ", "");
      var off = $(list[i]).offset();
      var w = $(list[i]).outerWidth();
      var sum = Math.ceil(parseFloat(w) + parseFloat(off.left));
      if (check == 0 && (sum > w_win || sum > w_win - 100)) {
        check = 1;
      }
      if (check == 1) {
        var text = $(list[i]).text();
        var link = $(list[i]).attr("href");
        var active = 0;
        if ($(list[i]).find(".text-favourite-report-active").length > 0) {
          active = 1;
        }
        moreList.push({
          text: text,
          link: link,
          classname: classname,
          active: active,
        });
        $(list[i]).hide();
      } else {
        $(list[i]).show();
      }
      //console.log(i,off,w,sum,(w_win - 100));
    }
    //console.log(list,moreList);
    if (moreList.length > 0) {
      $("#popup-favourite-report").html("");
      var n = moreList.length;
      for (var i = 0; i < n; i++) {
        var classname = "link-popup-favourite-report";
        if (moreList[i].active == 1) {
          classname += "-active";
        }
        var newLink =
          "<a class='" +
          classname +
          " " +
          moreList[i].classname +
          "' href='" +
          moreList[i].link +
          "'>" +
          moreList[i].text +
          "</a>";
        $("#popup-favourite-report").append(newLink);
      }
      $("#more-favourite-report").show();
    } else {
      $("#more-favourite-report").hide();
    }
  },
};

export default TopMenuCall;

/*$(document).ready(function(){
    TopMenuCall.event();
});*/
