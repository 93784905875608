import React from 'react';
import $ from 'jquery';
  
const LoadingCall = {
	event: function(){

	},
	show : function(){
		$(".loading-container").show();
		$('body').css("overflow","hidden");
	},
	hide : function(scrollHide){
		setTimeout(function(){
			$(".loading-container").hide();
			if(scrollHide != 1){
				$('body').css("overflow","auto");
			}
		},500);
	},
	imageUrl(){
        return process.env.PUBLIC_URL + "/images/loading.gif";
    },
	html(add_class = "", add_css = [], type = ""){
        let overlayClass = (type != "") ? "loading-overlay-" + type : "loading-overlay";
        overlayClass += (add_class != "") ? " " + add_class : "";
        let imageClass = (type != "") ? "loading-image-" + type : "loading-image";
        imageClass += (add_class != "") ? " " + add_class : "";

        let html = [];
        html.push(<div key="loading-1" className={overlayClass} style={add_css} />);
        html.push(<img key="loading-2" src={this.imageUrl()} className={imageClass} style={add_css} />);
        
        return html;
    }
}

export default LoadingCall;

$(document).ready(function(){
    LoadingCall.show();
});
