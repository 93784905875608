import React from 'react';
import Func from "../utils/Func";

export default {
    ConvertStringToObj(string){
        if(string === "" || string === null){
          return {};
        }
        var properties = string.split(', ');
        var obj = {};
        properties.forEach(function(property) {
            var tup = property.split(':');
            obj[tup[0]] = tup[1];
        });
        return obj;
    },
    PublicUrl(){
        return process.env.PUBLIC_URL;
    },
    ImageUrl(){
        return process.env.PUBLIC_URL + "/images/";
    },
    capitalize(s){
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    Space(height = "10px"){
        return <div style={{float: "left", width: "100%", height: height}} />
    },
    LoadingImageUrl(){
        return process.env.PUBLIC_URL + "/images/loading.gif";
    },
    IsArray(obj){
        return !!obj && obj.constructor === Array;
    },
    HtmlBoxTitle(text = ""){
        let bg = "#a2ebf7";
        bg = "#e6e3e3";
    
        const html = <div style={{float: "left", width: "100%", height: "40px", background: bg}}>
            <span style={{padding: "0 20px", fontSize: "15px", fontWeight: "bold", lineHeight: "40px"}}>
                {text}
            </span>
        </div>

        return html;
    },
    HtmlWarningRequired(type_name, field_name, value_selected = "", addon = []){
        const display = (value_selected != "") ? "none" : "inline";
        let width = "100%";
        if(addon.width != undefined && addon.width != ""){
            width = addon.width;
        }

        const title = <span className="warning-required-filter" id={"warning-required-filter-" + type_name + "-" + field_name} style={{display: display, float: "left", width: width, marginBottom: "10px", fontSize: "12px", color: "red", fontWeight: "initial"}}>
            ( The field is required )
        </span>
        
        return title;
    },
    HtmlSingleMultiSelectFilter(type, field, data = [], selected = [], addon = []){
        let def_title = '';
        let def_val = '';
        if(addon.def_val != undefined){
            def_val = addon.def_val;
        }
        if(addon.def_title != undefined){
            def_title = addon.def_title;
        }
        let required = (addon["required"] != undefined) ? addon["required"] : 0;
        let box_width = "100%";
        if(addon.width != undefined && addon.width != ""){
            box_width = addon.width;
        }
        let addClass = "";
        let inp_search_dis = "block";
        if(addon["inp-disable"] != undefined){
            addClass = " inp-disable";
            inp_search_dis = "none";
        }
        let relate_fields = "";
        let relate_field = 0;
        if(addon["relate-fields"] != undefined){
            relate_fields = addon["relate-fields"].join("|xxx|");
            relate_field = 1;
        }
        let res = this.HtmlMultiOptionsFilter(type, field, data, selected, "", {"relate-field": relate_field});
        //console.log(res);
        let div_selected = res.selected;
        let options = res.options;
        let value_selected = res.value_selected;

        let bdcolor = (required === 1 && value_selected === "") ? "#f07a7a" : "#ddd";

        let inp = <div className="box-inp-filter" id={"box-inp-filter-" + type + "-" + field} field-name={field} multi-value="1" required-value={required} relate-fields={relate_fields} inp-type="multiselect" style={{position: "relative", float: "left", width: box_width}} >
            <div style={{paddingRight: "0px"}}>
                <div style={{float: "left", width: "100%"}}>
                    <div className={"select-filter" + addClass} type-name={type} field-name={field} multi-value="1" style={{position: "relative", float: "left", width: "100%", boxSizing: "border-box", marginBottom: "10px", border: "1px solid " + bdcolor, cursor: "pointer"}}>
                        <div style={{display: "block", paddingRight: "27px", minHeight: "35px", maxHeight: "142px", overflowY: "auto"}}>
                            <input name={"inp-multi-select-" + field} className="inp-multi-select" id={"inp-select-filter-" + type + "-" + field} type="text" defaultValue="" autoComplete="off" style={{float: "left", cursor: "pointer", outline: "none", border: "0px", width: "95%", background: "none", marginTop: "8px", marginBottom: "8px", marginLeft: "8px", fontSize: "13px", color: "#535c69", "display": inp_search_dis}} placeholder="Search" type-name={type} field-name={field} />
                            {div_selected}
                        </div>
                        <span className="arrow-down" style={{position: "absolute", top: "50%", right: "10px", marginTop: "-5px", cursor: "pointer"}} />
                    </div>
                    <div id={"options-filter-" + type + "-" + field} style={{position: "relative", float: "left", width: "100%", maxHeight: "177px", minHeight: "60px", overflowY: "auto", border: "1px solid #ddd", borderTop: "0px", boxSizing: "border-box", marginBottom: "10px", marginTop: "-10px", display: "none"}}>
                        {options}
                        <div className="loading-option-filter" style={{position: "absolute", top: "0px", width: "100%", height: "100%", background: "#fff", display: "none"}}>
                            <img src={Func.LoadingImageUrl()} style={{margin: "0 auto", display: "block", height: "30px", marginTop: "14px", marginBottom: "14px"}} />
                        </div>
                    </div>
                </div>
            </div>
            <input name="def-title" type="hidden" value={def_title} />
            <input name="def-val" type="hidden" value={def_val} />
        </div>

        return inp;
    },
    HtmlMultiOptionsFilter(type, field, data = [], selected = [], search = "", addon = {}){
        let relate_field = (addon["relate-field"] != undefined) ? addon["relate-field"] : "";
        let value_selected = selected;
        let n = data.length;
        let li = [];
        let div_selected = [];
        div_selected.push(this.HtmlSingleSelectedFilter("",type,field,{notshow: 1, id: "tmp-selected-filter", "relate-field": relate_field}));
        let len_search = search.length;
        for(let i=0;i<n;i++){
            let name = data[i]["name"];
            let value = '';
            if(data[i]["value"] === undefined){
                value = name;
            }
            else{
                value = data[i]["value"];
            }
            let li_css = {position: "relative", minHeight: "25px", padding: "5px", fontSize: "13px", lineHeight: "25px"};
            let checked = false;
            //console.log("field=" + field + " selected=" + selected + " value=" + value);
            if(selected.indexOf(value) >= 0 && !(value === "" && selected === "")){
                checked = true;
                div_selected.push(this.HtmlSingleSelectedFilter(name,type,field,{index: i, "relate-field": relate_field}));
            }
            if(search != ""){
                let sp = name.toLowerCase().substr(0,len_search);
                let pos = (sp === search) ? true : false;
                if(pos === false){
                    li_css["display"] = "none";
                }
            }
            li.push(<li key={"li-option-filter-" + type + "-" + i} className="li-option-filter" type-name={type} field-name={field} value={value} multi-value="1" relate-field={relate_field} style={li_css}>
                <input type="checkbox" name={field + "[]"} className="checkbox-option-filter" type-name={type} field-name={field} index={i} id={"checkbox-option-filter-" + type + "-" + field + "-" +i} value={value} defaultChecked={checked} relate-field={relate_field} style={{position: "absolute", top: "8px", left: "5px"}} />
                <label htmlFor={"checkbox-option-filter-" + type + "-" + field + "-" + i} style={{marginLeft: "25px", display: "block", cursor: "pointer"}}>
                    {name}
                </label>
            </li>);
        }
        let ul = <ul className="ul-option-filter" style={{listStyle: "none", margin: "0px", padding: "0px"}}>
            {li}
        </ul>

        let res = {options: ul, selected: div_selected, value_selected: value_selected};
        return res;
    },
    HtmlSingleSelectedFilter(name, type, field, addon = []){
        let relate_field = (addon["relate-field"] != undefined) ? addon["relate-field"] : "";
        let add_css = {float: "left", padding: "0 10px", background: "#9AEDD5", fontSize: "13px", cursor: "pointer", marginLeft: "2px", marginRight: "1px", marginTop: "2px", marginBottom: "2px", minHeight: "31px", lineHeight: "31px", borderRadius: "2px", position: "relative"};
        if(addon["notshow"] !== undefined && addon["notshow"] === 1){
            add_css["display"] = "none";
        }
        let index = "";
        if(addon["index"] !== undefined){
            index = addon["index"];
        }
        let id = "";
        let className = "";
        if(addon["id"] !== undefined){
            id = addon["id"];
        }
        else{
            id = "selected-filter-" + type + "-" + field + "-" + index;
            className = "selected-filter-" + type + "-" + field;
        }
        let html = <div key={"selected-filter-" + id} id={id} className={className} style={add_css}>
            <span className="title" style={{marginRight: "15px"}}>
                {name}
            </span>
            <div className="close-selected-filter" type-name={type} field-name={field} index={index} relate-field={relate_field} style={{position: "absolute", top: "0px", right: "5px", zIndex: 1, height: "100%"}}>
                <span className="small-close-icon" style={{display: "block", marginTop: "12px", marginRight: "4px", marginLeft: "4px"}} />
            </div>
        </div>
        return html;
    },
    HtmlSingleSelectFilter(type, field, data = [], selected = "", addon = []){
        let def_title = '';
        let def_val = '';
        if(addon.def_val != undefined){
            def_val = addon.def_val;
        }
        if(addon.def_title != undefined){
            def_title = addon.def_title;
        }
        let required = (addon["required"] != undefined) ? addon["required"] : 0;
        let box_width = "100%";
        if(addon.width != undefined && addon.width != ""){
            box_width = addon.width;
        }
        let addClass = "";
        if(addon["inp-disable"] != undefined){
            addClass = " inp-disable";
        }
        let relate_fields = "";
        let relate_field = 0;
        if(addon["relate-fields"] != undefined){
            relate_fields = addon["relate-fields"].join("|xxx|");
            relate_field = 1;
        }

        let res = this.HtmlOptionsFilter(type, field, data, selected, "", {"relate-field": relate_field});
        //console.log(res);
        let div_selected = res.selected;
        let options = res.options;
        let value_selected = res.value_selected;

        let bdcolor = (required === 1 && value_selected === "") ? "#f07a7a" : "#ddd";

        let inp = <div className="box-inp-filter" id={"box-inp-filter-" + type + "-" + field} field-name={field} multi-value="0" required-value={required} relate-fields={relate_fields} style={{position: "relative", float: "left", width: box_width}} >
            <div style={{paddingRight: "0px"}}>
                <div style={{float: "left", width: "100%"}}>
                    <div className={"select-filter" + addClass} type-name={type} field-name={field} multi-value="0" style={{position: "relative", float: "left", width: "100%", boxSizing: "border-box", marginBottom: "10px", border: "1px solid " + bdcolor, cursor: "pointer"}}>
                        <div style={{display: "block", paddingRight: "27px", minHeight: "35px"}}>
                            <span className={"selected-filter-" + type + "-" + field} style={{float: "left", fontSize: "14px", marginLeft: "8px", marginTop: "8px", marginRight: "7px"}}>
                                {div_selected}
                            </span>
                            <input name={"inp-multi-select-" + field} className="inp-multi-select" id={"inp-select-filter-" + type + "-" + field} type="text" defaultValue="" autoComplete="off" style={{float: "left", cursor: "pointer", outline: "none", border: "0px", width: "auto", background: "none", display: "none", marginTop: "8px", marginBottom: "8px", marginLeft: "8px", fontSize: "13px", color: "#535c69"}} placeholder="Search" type-name={type} field-name={field} />
                        </div>
                        <span className="arrow-down" style={{position: "absolute", top: "50%", right: "10px", marginTop: "-5px", cursor: "pointer"}} />
                    </div>
                    <div id={"options-filter-" + type + "-" + field} style={{position: "relative", float: "left", width: "100%", maxHeight: "177px", minHeight: "60px", overflowY: "auto", border: "1px solid #ddd", borderTop: "0px", boxSizing: "border-box", marginBottom: "10px", marginTop: "-10px", display: "none"}}>
                        {options}
                        <div className="loading-option-filter" style={{position: "absolute", top: "0px", width: "100%", height: "100%", background: "#fff", display: "none"}}>
                            <img src={Func.LoadingImageUrl()} style={{margin: "0 auto", display: "block", height: "30px", marginTop: "14px", marginBottom: "14px"}} />
                        </div>
                    </div>
                    <input name={field} type="hidden" value={selected} />
                </div>
            </div>
            <input name="def-title" type="hidden" value={def_title} />
            <input name="def-val" type="hidden" value={def_val} />
        </div>

        return inp;
    },
    HtmlOptionsFilter(type, field, data = [], selected = "", search = "", addon = {}){
        let relate_field = (addon["relate-field"] != undefined) ? addon["relate-field"] : "";
        let value_selected = selected;
        let n = data.length;
        let li = [];
        let div_selected = '';
        let len_search = search.length;
        for(let i=0;i<n;i++){
            let name = data[i]["name"];
            let value = '';
            if(data[i]["value"] === undefined){
                value = name;
            }
            else{
                value = data[i]["value"];
            }
            let li_css = {position: "relative", minHeight: "25px", padding: "5px", fontSize: "13px", lineHeight: "25px"};
            if(value === selected){
                li_css["backgroundColor"] = "#f1f1f1";
                div_selected = name;
            }
            if(search != ""){
                let sp = name.toLowerCase().substr(0,len_search);
                let pos = (sp === search) ? true : false;
                if(pos === false){
                    li_css["display"] = "none";
                }
            }
            li.push(<li key={"li-option-filter-" + type + "-" + i} className="li-option-filter" type-name={type} field-name={field} value={value} multi-value="0" relate-field={relate_field} style={li_css}>
                <label htmlFor={"checkbox-option-filter-" + type + "-" + field + "-" + i} style={{marginLeft: "5px", display: "block", cursor: "pointer"}}>
                    {name}
                </label>
            </li>);
        }
        let ul = <ul className="ul-option-filter" style={{listStyle: "none", margin: "0px", padding: "0px"}}>
            {li}
        </ul>

        let res = {options: ul, selected: div_selected, value_selected: value_selected};
        return res;
    },
    HtmlSingleDateFilter(type, field, selected = "", addon = []){
        let required = 0;
        let value = "";
        if(selected.length > 0){
            value = selected;
        }
        let box_width = "100%";
        if(addon["width"] !== undefined && addon["width"] !== ""){
            box_width = addon["width"];
        }
        let bdcolor = (required === 1 && selected === "") ? "#f07a7a" : "#ddd";
        let inp = <div className="box-inp-filter" id={"box-inp-filter-" + type + "-" + field} field-name={field} required-value={required} style={{position: "relative", float: "left", width: box_width}}>
            <div style={{paddingRight: "0px"}}>
                <input name={field} className={"input-text-filter inp-filter-" + type} type="date" defaultValue={value} placeholder="yyyy-mm-dd" type-name={type} style={{display: "block", boxSizing: "border-box", marginBottom: "10px", padding: "8px", border: "1px solid " + bdcolor, height: "35px", width: "100%"}} />
            </div>
        </div>
        
        return inp;
    },
    HtmlSingleInputFilter(type, field, selected = "", addon = []){
        let required = 0;
        let value = "";
        if(selected.length > 0){
            value = selected;
        }
        let box_width = "100%";
        if(addon["width"] !== undefined && addon["width"] !== ""){
            box_width = addon["width"];
        }
        let addClass = "";
        if(addon["inp-disable"] != undefined){
            addClass = " inp-disable";
        }
        let bdcolor = (required === 1 && selected === "") ? "#f07a7a" : "#ddd";
        let inp = <div className="box-inp-filter" id={"box-inp-filter-" + type + "-" + field} field-name={field} required-value={required} style={{position: "relative", float: "left", width: box_width}}>
            <div style={{paddingRight: "0px"}}>
                <input name={field} className={"input-text-filter inp-filter-" + type + addClass} type="text" defaultValue={value} type-name={type} style={{display: "block", boxSizing: "border-box", marginBottom: "10px", padding: "8px", border: "1px solid " + bdcolor, height: "37px", width: "100%"}} />
            </div>
        </div>
    
        return inp;
    },
    HtmlLoading(add_class = "", add_css = [], type = ""){
        let overlayClass = (type != "") ? "loading-overlay-" + type : "loading-overlay";
        overlayClass += (add_class != "") ? " " + add_class : "";
        let imageClass = (type != "") ? "loading-image-" + type : "loading-image";
        imageClass += (add_class != "") ? " " + add_class : "";

        let html = [];
        html.push(<div key="loading-1" className={overlayClass} style={add_css} />);
        html.push(<img key="loading-2" src={Func.LoadingImageUrl()} className={imageClass} style={add_css} />);
        
        return html;
    },
    HtmlSmallPopupAlert(content = "", title = "", addon = []){
        let width = addon["width"];
        let height = addon["height"];
        let display = (addon["is_show"] !== undefined && addon["is_show"] === true) ? "block" : "none";
        let btn_close = "";
        if(addon["no_close"] === undefined || addon["no_close"] === false){
            btn_close = <div className="small-popup-close" />;
        }
        const popup = [];
        popup.push(<div key="small-popup-1" className="small-popup small-popup-overlay" style={{display: display}}/>);
        popup.push(<div key="small-popup-2" className="small-popup small-popup-innerhtml" style={{width: width + "px", height: height + "px", display: display}}>
            {btn_close}
            <div className="small-popup-content" style={{height: height + "px", display: display}}>
                <div className="small-popup-title">
                    <span className="small-popup-title-span">{title}</span>
                </div>
                <div className="small-popup-inner-content" style={{height: (height - 65) + "px"}}>
                    {content}
                </div>
            </div>
        </div>);

        return popup;
    }
};

