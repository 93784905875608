// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkmark_success {
    width: 28px;
    height: 50px;
    position: absolute;
    top: -4px;
    left: 18px; /* IE 9 */ /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_success_stem {
    position: absolute;
    width: 6px;
    height: 37px;
    background-color: #4DBE9D;
    left: 17px;
    top: 6px;
}

.checkmark_success_kick {
    position: absolute;
    width: 17px;
    height: 6px;
    background-color: #4DBE9D;
    left: 5px;
    top: 37px;
}`, "",{"version":3,"sources":["webpack://./src/components/CopyRates/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,UAAU,EACoB,SAAS,EACL,0BAA0B;IAC5D,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,SAAS;IACT,SAAS;AACb","sourcesContent":[".checkmark_success {\r\n    width: 28px;\r\n    height: 50px;\r\n    position: absolute;\r\n    top: -4px;\r\n    left: 18px;\r\n    -ms-transform: rotate(45deg); /* IE 9 */\r\n    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */\r\n    transform: rotate(45deg);\r\n}\r\n\r\n.checkmark_success_stem {\r\n    position: absolute;\r\n    width: 6px;\r\n    height: 37px;\r\n    background-color: #4DBE9D;\r\n    left: 17px;\r\n    top: 6px;\r\n}\r\n\r\n.checkmark_success_kick {\r\n    position: absolute;\r\n    width: 17px;\r\n    height: 6px;\r\n    background-color: #4DBE9D;\r\n    left: 5px;\r\n    top: 37px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
