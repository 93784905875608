import { createContext, useContext, useState, useEffect } from "react";
import API from "../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../utils/encryption";
import { useLogin } from "./LoginContext";

const mainContextDefaultValues = {
  mainData: {},
  storeLoginData: () => {},
};

const MainContext = createContext(mainContextDefaultValues);

export function MainProvider({ children, url }) {
  const { loginData } = useLogin();
  const [mainData, setMainData] = useState([]);
  useEffect(() => {
    if (loginData?.conv_user_log_id && loginData?.conv_user_goo_id) {
      const mainFunc = async () =>
        await API.post("maindata2", {
          params: encryptJsonObject({
            url: url,
            conv_user_log_id: loginData.conv_user_log_id,
            conv_user_goo_id: loginData.conv_user_goo_id,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success") {
            setMainData(decrypt);
          } else {
            setMainData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          console.log(`Failes : ${err}`);
          setMainData({ err: 1 });
        });
    }
  }, [url, loginData?.conv_user_log_id, loginData?.conv_user_goo_id]);

  const storeMainData = (data) => {
    setMainData(data);
  };

  const value = {
    mainData: mainData,
    storeMainData: storeMainData,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export const useMain = () => useContext(MainContext);
