import React, { useState, useEffect } from "react";
import $ from "jquery";
import API from "../../utils/API";
import Func from "../../utils/Func";
import TopBar from "./TopBar";
import SalesReportContent from "./SalesReportContent";
import ScreenCall from "../../js/ScreenCall";
import LoadingCall from "../../js/LoadingCall";
import TopMenuCall from "../../js/TopMenuCall";
import PopupCall from "../../js/PopupCall";
import "../../css/style.css";
import { useMain } from "../../context/MainContext";
import CopyRatesFunc from "../CopyRates/func";
import ExportSqlFunc from "../ExportSql/func";

//global variable
window.$report_type_name = "";
window.$current_active_report_type = "";
window.$current_active_report_id = 0;
window.$current_active_report = false;
window.$currency_list = [];
window.$field_details = [];

const Container = ({ url }) => {
  const { mainData } = useMain();
  // console.log("mainData=", mainData);
  if (mainData.length === 0 || mainData?.login_data === undefined) {
    return <div>{LoadingCall.html("loading-container")}</div>;
  } else if (mainData?.login_data?.err_msg !== "") {
    ContainerFunc.event();
    const err_msg =
      mainData?.login_data?.err_msg !== undefined
        ? mainData?.login_data?.err_msg
        : "Sorry, You cannot access this page :)";
    return (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          margin: "40px 0",
          display: "block",
          textAlign: "center",
        }}
      >
        {err_msg}
      </div>
    );
  }

  if (mainData?.user_data === undefined) {
    mainData.user_data = { report_type_name: "", report_title: "Main Menu" };
    mainData.login_data = [];
  }

  let content = "";
  if (mainData?.content_data) {
    if (url === "copy_rates.php") {
      content = CopyRatesFunc.GetContent(mainData.content_data);
    } else if (url === "export_sql.php") {
      content = ExportSqlFunc.GetContent(mainData.content_data);
    }
  }

  const report_type_name = mainData.user_data.report_type_name;
  const right_content = SalesReportContent.ContentSalesReport(
    report_type_name,
    content
  );

  ContainerFunc.SetNameTopBar(mainData.login_data.user_name);
  ContainerFunc.event();

  let outercontent = (
    <div
      id="box-outer-content-report"
      style={{
        position: "relative",
        float: "left",
        width: "100%",
        background: "#f1f1f1",
      }}
    >
      <div
        style={{
          position: "relative",
          float: "left",
          width: "100%",
          minHeight: "500px",
        }}
      >
        <div
          id="workarea-content"
          style={{
            display: "block",
            overflowX: "hidden",
            minHeight: "941px",
            backgroundColor: "#fff",
          }}
        >
          {right_content}
        </div>
      </div>
      <div
        id="bg_black_menu"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          background: "#000",
          opacity: "0.6",
          width: "100%",
          height: "100%",
          zIndex: "1000",
          display: "none",
        }}
      />
    </div>
  );

  return <div id="box-inner-container">{outercontent}</div>;
};

const ContainerFunc = {
  event() {
    $(document).ready(function () {
      //ScreenCall.setWidth();
      //ScreenCall.setHeight();
      LoadingCall.hide();
      //TopMenuCall.set_init_favourite_bar();
      //TopMenuCall.event();
      PopupCall.event();
      //menuLeftCall.event();
    });
    $(window).resize(function () {
      //ScreenCall.setWidth();
      //ScreenCall.setHeight();
    });
    $(window).on("load", function () {
      LoadingCall.hide();
    });
  },
  PopupContainer() {
    let popup = [];
    popup.push(
      <div
        className="popup-container popup-overlay"
        key="popup-container-1"
      ></div>
    );
    popup.push(
      <div className="popup-container popup-innerhtml" key="popup-container-2">
        <div className="popup-close" />
        <div className="popup-content">
          <div className="popup-inner-content"></div>
        </div>
      </div>
    );

    return popup;
  },
  SetNameTopBar(name) {
    document.getElementsByClassName("user-name")[0].innerText = name;
    $(".user-logout").show();
  },
};

export { Container, ContainerFunc };
