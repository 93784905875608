import React from "react";
import ScreenCall from "../../js/ScreenCall";
import LoadingCall from "../../js/LoadingCall";
import TopMenuCall from "../../js/TopMenuCall";
import "../../css/style.css";

const NotFound = ({ location }) => {
  LoadingCall.hide();
  return (
    <div
      style={{
        fontSize: "20px",
        fontWeight: "bold",
        margin: "40px 0",
        display: "block",
        textAlign: "center",
      }}
    >
      Sorry, You cannot access this page :)
    </div>
  );
};

export default NotFound;
