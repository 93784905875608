import axios from 'axios';
import config from "../config";
import { encryptData } from '../utils/encryption';


// const token = btoa(`${process.env.REACT_APP_SERVERNAME}:${process.env.REACT_APP_APIKEY}`); // Base64 encode the credentials

const dataToEncrypt = `${process.env.REACT_APP_SERVERNAME}:${process.env.REACT_APP_APIKEY}`;
const token = encryptData(dataToEncrypt, process.env.REACT_APP_ENCRYPTION_SECRET_KEY, true);
const encodedToken = encodeURIComponent(token);
// console.log('token:', token);
// console.log('encodedToken:', encodedToken);

export default axios.create({
    baseURL: config.apiUrl,
    // withXSRFToken: true,
    headers: {
        "Authorization": `Basic ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    } 
    //responseType: "json"
});