import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import API from "../../utils/API";
import Func from '../../utils/Func';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import LoadingCall from "../../js/LoadingCall";
import PopupCall from "../../js/PopupCall";
import M from 'materialize-css';

window.$conv_tp_log_id = "";
let typing;

const ExportSqlFunc = {
	event: function(){
        $(document).ready(function(){
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.FormSelect.init(elems);
        });
    },
    GetContent(data){
        //console.log(data);
        let type_name = "export-sql";
        const form = ExportSqlFunc.Form();
    
        let content = <div className="container" style={{fontSize: "13px"}}>
            {form}
        </div>;
    
        return content;
    },
    Form(){
        let form = <form id="form" action="" className="row" style={{marginTop: "30px"}}>
            <div className="row">

            </div>
            <div className="input-field col s12">
              <label htmlFor="inp-countries" className="col s4">Countries Database</label>
              <select className="sidenav col s4" id="inp-countries" name="inp-countries">
                <option value="" disabled defaultValue>Choose Countries</option>
                <option value="KH">Cambodia</option>
                <option value="CN">China</option>
                <option value="ID">Indonesia</option>
              </select>
            </div>
        </form>

        return form;
    }
}

export default ExportSqlFunc;