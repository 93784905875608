import React from "react";
import { Container } from "../Container/index";
import "./style.css";
import ExportSqlFunc from "./func";
import { MainProvider } from "../../context/MainContext";

const ExportSql = () => {
  const url = "export_sql.php";
  ExportSqlFunc.event();
  return (
    <MainProvider url={url}>
      <Container url={url} />
    </MainProvider>
  );
};

export default ExportSql;
