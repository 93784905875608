import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TopBar from "../Container/TopBar";
import CopyRates from "../CopyRates";
import ExportSql from "../ExportSql";
import NotFound from "../NotFound";
import LoadingCall from "../../js/LoadingCall";
import { useLogin } from "../../context/LoginContext";

export default function RouteContent() {
  const { loginData, storeLoginData } = useLogin();
  if (
    loginData?.conv_user_id !== parseInt(process.env.REACT_APP_USER_ID) &&
    process.env.REACT_APP_SYSTEM_CLOSED === "true"
  ) {
    return (
      <div id="container" className="container" style={{ width: "99%" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "#333",
            marginTop: "0%",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <p>
            We regret to inform you that our system is temporarily unavailable.
          </p>
          <p>
            We will resume operations on <strong>Monday</strong>.
          </p>
          <p>
            We apologize for any inconvenience and appreciate your
            understanding.
          </p>
        </div>
      </div>
    );
  }
  if (
    loginData.length === 0 ||
    loginData?.conv_user_log_id === undefined ||
    loginData?.conv_user_log_id === ""
  ) {
    return (
      <div id="container">
        <TopBar loginData={loginData} storeLoginData={storeLoginData} />
        <div id="box-container" style={{ width: "100%" }}>
          {LoadingCall.html("loading-container")}
        </div>
      </div>
    );
  } else if (loginData.err === 1) {
    return <NotFound />;
  }
  return (
    <div id="container">
      <TopBar loginData={loginData} storeLoginData={storeLoginData} />
      <div id="box-container" style={{ width: "100%" }}>
        <Router>
          <Switch>
            <Route
              path="/tourplan/copy_rates.php"
              exact
              component={CopyRates}
            />
            <Route
              path="/tourplan/export_sql.php"
              exact
              component={ExportSql}
            />
            {<Route path="/tourplan/" component={NotFound} />}
          </Switch>
        </Router>
        {LoadingCall.html("loading-container")}
      </div>
    </div>
  );
}
