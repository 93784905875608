import React, { useState, useEffect, useRef } from "react";
import config from "../../config";
import { GoogleLogin } from "react-google-login";
import Cookies from "js-cookie";

function SignIn({ googleProfile, setGoogleProfile }) {
  const CLIENT_ID = config.clientId;
  const COOKIE_NAME = "google_login_token";
  const [loginData, setLoginData] = useState({ autoLoad: false, key: 1 });
  const timerRef = useRef(null); // Use useRef to persist the timer value across renders


  // Function to set the login token in a cookie
  const setLoginCookie = (email, googleId) => {
    const tokenData = { 
      email,
      googleId, 
      timestamp: Date.now() }; // Add additional data if needed
    Cookies.set(COOKIE_NAME, JSON.stringify(tokenData), { expires: 1 }); // 1 day expiration
  };

  // Function to check the login token from the cookie
  const getLoginCookie = () => {
    const cookie = Cookies.get(COOKIE_NAME);
    if (cookie) {
      try {
        const parsed = JSON.parse(cookie);
        // Check if both email and googleId exist in the parsed cookie
        return (parsed.email && parsed.googleId) ? parsed : null;
      } catch (error) {
        console.error("Failed to parse login cookie:", error);
        return null;
      }
    }
    return null;
  };

  const onSuccess = (res) => {
    console.log("Login Success: currentUser");
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the timer using useRef
    }
    const email = res.profileObj.email;
    const googleId = res.profileObj.googleId;
    setGoogleProfile({ 
      email: email, 
      googleId: googleId,
      err: 0, 
      err_msg: "" 
    });
    setLoginCookie(email, googleId); // Set cookie on successful login
  };

  const onFailure = (res) => {
    console.log("Login failed:", res);
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the timer using useRef
    }
    setGoogleProfile({ 
      email: "", 
      googleId: "",
      err: 1, 
      err_msg: res.error 
    });
    Cookies.remove(COOKIE_NAME); // Clear the cookie on failure
  };

  useEffect(() => {
    const savedProfile = null;//getLoginCookie(); // Check if a valid cookie exists

    if (savedProfile && savedProfile?.email && savedProfile?.googleId) {
      setGoogleProfile({
        email: savedProfile.email,
        googleId: savedProfile.googleId,
        err: 0,
        err_msg: "",
      });
    } else if (!loginData.autoLoad) {
      // Start the timeout only if not auto-loaded already
      timerRef.current = setTimeout(() => {
        console.log("Login Timeout called!");
        if (document.getElementById("is_loaded").value === "0") {
          setLoginData((prevState) => ({
            ...prevState,
            autoLoad: true,
            key: prevState.key + 1,
          }));
        }
        clearTimeout(timerRef.current);
      }, 4000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clean up the timer when the component unmounts
      }
    };
  }, [loginData.autoLoad, setGoogleProfile, setLoginData]);

  
  return (
    <div id="btn-google-login" style={{ display: "none" }}>
      <GoogleLogin
        key={loginData.key}
        clientId={CLIENT_ID}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={true}
        autoLoad={loginData.autoLoad}
        accessType={"offline"}
      />
      <input
        id="is_loaded"
        name="is_loaded"
        type="hidden"
        value={"email" in googleProfile && googleProfile.email !== "" ? 1 : 0}
      />
    </div>
  );
}

export default SignIn;
