import React, { useState, useEffect } from "react";
import "./App.css";
import RouteContent from "./components/route";
import GoogleLogin from "./components/Google/signin";
import { LoginProvider } from "./context/LoginContext";

const App = () => {
  useEffect(() => {
    let documentTitle = "Copy Rate";
    document.title = documentTitle;
  }, []);
  const def_email = "";
  const def_googleId = "";
  const [googleProfile, setGoogleProfile] = useState({
    email: def_email,
    googleId: def_googleId,
    err: 0,
    err_msg: "",
  });
  const googleLogin = (
    <GoogleLogin
      googleProfile={googleProfile}
      setGoogleProfile={setGoogleProfile}
    />
  );
  return (
    <div id="container" className="container" style={{ width: "99%" }}>
      {googleLogin}
      <LoginProvider googleProfile={googleProfile}>
        <RouteContent />
      </LoginProvider>
    </div>
  );
};

export default App;
