import { createContext, useContext, useState, useEffect } from "react";
import API from "../utils/API";
import { decryptWithApiResponse, encryptJsonObject } from "../utils/encryption";

const loginContextDefaultValues = {
  google_email: "",
  google_id: "",
  loginData: {},
  storeLoginData: () => {},
};

const LoginContext = createContext(loginContextDefaultValues);

export function LoginProvider({ children, googleProfile }) {
  const [loginData, setLoginData] = useState([]);
  useEffect(() => {
    if (googleProfile?.email && googleProfile?.googleId) {
      const mainFunc = async () =>
        await API.post("google_login", {
          params: encryptJsonObject({
            google_email: googleProfile.email,
            google_id: googleProfile.googleId,
          }),
        });
      mainFunc()
        .then((res) => {
          const decrypt = decryptWithApiResponse(res?.data);
          // console.log("response=", decrypt);
          if (decrypt?.status === "success" && decrypt?.login_data) {
            setLoginData(decrypt.login_data);
          } else {
            setLoginData({
              err: 1,
              err_msg: `Failes : ` + decrypt?.err_msg ?? "",
            });
          }
        })
        .catch((err) => {
          console.log(`Failes : ${err}`);
          setLoginData({ err: 1 });
        });
    }
  }, [googleProfile?.email, googleProfile?.googleId]);

  const storeLoginData = (data) => {
    setLoginData(data);
  };

  const value = {
    google_email: googleProfile?.email,
    google_id: googleProfile?.googleId,
    loginData: loginData,
    storeLoginData: storeLoginData,
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
}

export const useLogin = () => useContext(LoginContext);
